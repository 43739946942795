/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";
import { expenseAccountsService } from "./expenseAccountsService";
import { bankAccountsService } from "./bankAccountsService";
import { billsItemService } from "./billsItemService";
import authService from "./authService";

let customReceiptsItems = (data, name) => {
  if (data[name] && typeof data[name] === "string") {
    data[name] = JSON.parse(data[name]);
  } else if (!data[name]) {
    data[name] = [];
  }
  let addEmpty = true;
  data[name].forEach((item) => {
    if (!item.expenseAccount && !item.amount) {
      addEmpty = false;
    }
  });
  if (addEmpty) {
    data[name].push({
      row: data[name].length + 1,
      _id: data[name].length + 1,
      expenseAccount: "",
      amount: "",
    });
  }
  return data[name];
};

export const receiptsDefinition = {
  baseUrl: "/receipts",
  sqlUrl: "/bills",
  viewTitle: "Receipts",
  editTitle: "Edit Receipts",
  createTitle: "Create Receipts",
  columnNames: [
    "date",
    "amount",
    "expenseAccount",
    "paymenttype",
    "description",
  ],
  totalsColumns: ["amount"],
  fieldNames: [
    "date",
    "expenseAccount",
    "amount",
    "paymenttype",
    "description",
    "itemized",
  ],
  createPermissions: ["[all]"],
  deletePermissions: ["[all]"],
  readPermissions: ["[all]"],
  writePermissions: ["[all]"],
  redirectAfterSave: "/home",
  fields: {
    date: {
      label: "Date",
      type: "date",
      required: true,
      default: "@Today",
    },
    amount: {
      label: "Total Amount",
      type: "currency",
      required: true,
    },
    paymenttype: {
      label: "Payed with",
      type: "select",
      selections: bankAccountsService,
      sorted: true,
      required: true,
      filterSelections: (selection, value) => {
        return selection.filter(
          (s) =>
            ("," + s.options + ",").indexOf(",showinreceipts,") !== -1 &&
            (!s.user || s.user === authService.getProfile().token)
        );
      },
    },
    expenseAccount: {
      label: "Expense Account",
      type: "select",
      selections: expenseAccountsService,
      sorted: true,
      required: true,
      focus: false,
      filterSelections: (selection, value) => {
        return selection.filter(
          (s) => ("," + s.options + ",").indexOf(",showinreceipts,") !== -1
        );
      },
    },
    description: {
      label: "Description",
      type: "text",
      length: 255,
      required: false,
    },
    itemized: {
      label: "Itemized",
      type: "rows",
      service: billsItemService,
      customview: customReceiptsItems,
      showOnly:
        "expenseAccount:670f3090982594b1b070ca051ccbf8578cc8e70a7a4f10a3e0723891a1f021fd",
    },
  },
};

class EntryServiceFiltered extends EntryService {
  async getAllAsync() {
    let data = await super.getAllAsync();
    return data.filter((row) => !row.stmt_desc);
  }

  async getAllAsyncLookup() {
    let data = await super.getAllAsyncLookup();
    return data.filter((row) => !row.stmt_desc);
  }
}

export let receiptsService = new EntryServiceFiltered(receiptsDefinition);
