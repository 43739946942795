export default class Currency {
  cents = 0;

  constructor(value) {
    if (value) {
      this.cents = this.toNumber(value);
    }
  }

  toNumber(value) {
    if (value) {
      if (typeof value === "string") {
        return Math.round(parseFloat(value) * 100);
      } else {
        return Math.round(value * 100);
      }
    } else {
      return 0;
    }
  }

  add(value) {
    this.cents += this.toNumber(value);
    return this;
  }

  sub(value) {
    this.cents -= this.toNumber(value);
    return this;
  }

  getValue() {
    return this.cents / 100;
  }

  set(value) {
    this.cents = this.toNumber(value);
    return this;
  }

  toString() {
    let c = this.cents;
    let minus = "";
    if (c < 0) {
      minus = "-";
      c = -c;
    }

    let ret = "";

    if (c < 10) {
      ret = minus + "0.0" + String(c);
    } else if (c < 100) {
      ret = minus + "0." + String(c);
    } else {
      ret =
        minus +
        String(c).substring(0, String(c).length - 2) +
        "." +
        String(c).substring(String(c).length - 2);
    }
    return ret;
  }
}
