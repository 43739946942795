/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

function getUrlVars() {
  var vars = {};
  window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = decodeURI(value);
    }
  );
  return vars;
}

function getUrlVar(key) {
  let vars = getUrlVars();
  if (vars === undefined) {
    return undefined;
  }
  return vars[key];
}

function isLocal() {
  if (
    window.location.hostname === "www.agolzer.com" ||
    window.location.hostname === "agolzer.com" ||
    window.location.hostname === "financial.agolzer.com"
  ) {
    return false;
  } else {
    return true;
  }
}

function getDbUrl() {
  let publicUrl = `${process.env.PUBLIC_URL}`;
  if (isLocal()) {
    return "https://financial.agolzer.com/db/";
  } else {
    return publicUrl + "/db/";
  }
}

function randomUUID() {
  let ret = "";
  for (let i = 0; i < 64; i++) {
    ret += Math.floor(Math.random() * 16).toString(16);
  }
  return ret;
}

function formatCurrency(amount) {
  if (!amount) {
    return "0.00";
  }
  amount = String(amount);
  if (amount.indexOf(".") === -1) {
    return amount + ".00";
  }
  if (amount.indexOf(".") + 2 === amount.length) {
    return amount + "0";
  }
  return amount;
}

function getBuild() {
  return "20230410_120248";
}

export default {
  getUrlVars,
  getUrlVar,
  getDbUrl,
  randomUUID,
  formatCurrency,
  getBuild,
  isLocal,
};
