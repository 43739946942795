/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

function twoDigits(value) {
  if (value < 10) {
    return "0" + String(value);
  } else {
    return String(value);
  }
}

function getLastDayInMonth(year, month) {
  if (month === 2) return 30;
  if (month === 4) return 30;
  if (month === 6) return 30;
  if (month === 9) return 30;
  if (month === 11) return 30;
  if (month !== 2) return 31;
  if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
    return 29;
  }
  return 28;
}

//from/to must be in the format of "YYYY-MM-DD"
function sqlDateList(from, to, maxElements) {
  if (from.length !== 10 || from[4] !== "-" || from[7] !== "-") {
    return undefined;
  }
  if (to.length !== 10 || to[4] !== "-" || to[7] !== "-") {
    return undefined;
  }

  let fromYear = parseInt(from.substring(0, 4));
  let fromMonth = parseInt(from.substring(5, 7));
  let fromDay = parseInt(from.substring(8, 10));
  let toYear = parseInt(to.substring(0, 4));
  let toMonth = parseInt(to.substring(5, 7));
  let toDay = parseInt(to.substring(8, 10));

  let ret = [];
  while (fromYear <= toYear) {
    while (fromMonth <= toMonth || fromYear < toYear) {
      while (fromDay <= toDay || fromYear < toYear || fromMonth < toMonth) {
        ret.push(
          String(fromYear) +
            "-" +
            twoDigits(fromMonth) +
            "-" +
            twoDigits(fromDay)
        );
        fromDay++;
        if (fromDay > getLastDayInMonth(fromYear, fromMonth)) {
          fromDay = 1;
          break;
        }
      }
      fromMonth++;
      if (fromMonth > 12) {
        fromMonth = 1;
        break;
      }
      if (maxElements && ret.length >= maxElements) {
        return ret;
      }
    }
    fromYear++;
  }
  return ret;
}

// sqldate must be in the format of "YYYY-MM-DD"
// if sqldate is "undefined", "null" or "" it will return ""
// on conversion errors, it returns "undefined"
function SQL_to_MDY(sqldate) {
  //handle empty sql date
  if (sqldate === undefined || sqldate === null || sqldate === "") {
    return "";
  }

  //handle invalid format of sql date
  if (sqldate.length !== 10 || sqldate[4] !== "-" || sqldate[7] !== "-") {
    return undefined;
  }

  //make sure the positions (except the hyphens) are all numeric
  let pos = [0, 1, 2, 3, 5, 6, 8, 9];
  for (let p = 0; p < pos.length; p++) {
    if (sqldate[pos[p]] < "0" || sqldate[pos[p]] > "9") {
      return undefined;
    }
  }

  //read in year/month/day value
  let year = parseInt(sqldate.substring(0, 4));
  let month = parseInt(sqldate.substring(5, 7));
  let day = parseInt(sqldate.substring(8, 10));

  //make sure numbers are valid calendar months/days
  if (
    month === 0 ||
    month > 12 ||
    day === 0 ||
    day > getLastDayInMonth(year, month)
  ) {
    return undefined;
  }

  //eliminate century portion of year if in 21st century
  if (year >= 2000 && year < 2100) {
    year = year % 100;
  }

  return month + "/" + day + "/" + year;
}

function MDY_to_SQL(mdy) {
  if (mdy === "") {
    return "";
  }
  let parts = mdy.split("/");
  if (parts.length === 2) {
    parts.push(new Date().getUTCFullYear());
  }
  if (parts.length !== 3) {
    return undefined;
  }
  let month = parseInt(parts[0]);
  let day = parseInt(parts[1]);
  let year = parseInt(parts[2]);
  if (year < 100) {
    year = 2000 + year;
  }
  if (
    month <= 0 ||
    month > 12 ||
    day <= 0 ||
    day > getLastDayInMonth(year, month)
  ) {
    return undefined;
  }
  let ret = year + "-" + twoDigits(month) + "-" + twoDigits(day);
  return ret;
}

function sqltime_to_ustime(value) {
  if (!value) {
    return "";
  }
  let hourminute = value.split(":");
  if (isNaN(hourminute[0])) {
    return value;
  } else if (hourminute.length >= 2 && isNaN(hourminute[1])) {
    return value;
  }
  let hour = parseInt(hourminute[0]);
  let isPM = false;
  if (hour > 11) {
    hour -= 12;
    isPM = true;
  }
  let minute = parseInt(hourminute[1]);

  return (
    (hour === 0 ? "12" : twoDigits(hour)) +
    ":" +
    twoDigits(minute) +
    " " +
    (isPM ? "pm" : "am")
  );
}

function ustime_to_sqltime(value) {
  if (!value) {
    return value;
  }
  try {
    let isPM = false;
    value = value.trim();
    if (value.endsWith("am")) {
      value = value.substring(0, value.length - 2);
    } else if (value.endsWith("pm")) {
      value = value.substring(0, value.length - 2);
      isPM = true;
    }
    let hourMinute = value.split(":");
    let hour = parseInt(hourMinute[0].trim());
    let minute = parseInt(hourMinute[1].trim());
    if (hour === 12 && isPM === false) {
      hour = 0;
    } else if (isPM) {
      hour += 12;
    }
    return String(hour) + ":" + String(minute);
  } catch (e) {
    //invalid format
    return value;
  }
}

function getEpoch(sqldate) {
  if (
    sqldate &&
    (sqldate.length !== 10 || sqldate[4] !== "-" || sqldate[7] !== "-")
  ) {
    return undefined;
  }
  return new Date(sqldate).getTime();
}

function roundFraction(value, fraction, nanText) {
  if (isNaN(value)) {
    if (nanText === undefined) {
      return value;
    } else {
      return nanText;
    }
  }
  value = parseFloat(value) * Math.pow(10, fraction);
  if (value > 0) value += 0.5;
  if (value < 0) value -= 0.5;
  value = value / Math.pow(10, fraction);
  let values = String(value).split(".");
  value = values[0] + ".";
  if (values.length === 1) {
    for (let i = 0; i < fraction; i++) {
      value += "0";
    }
  } else {
    for (let i = 0; i < fraction; i++) {
      if (values[1].length >= fraction) {
        value += values[1].substring(i, i + 1);
      } else {
        value += "0";
      }
    }
  }
  return value;
}

function getMonthOptions(start, end) {
  let ret = [];
  for (let i = start; i <= end; i++) {
    let now = new Date();
    now = new Date(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1 + i, 0)
    );
    let strMonth = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let id =
      twoDigits(now.getUTCFullYear()) +
      "-" +
      twoDigits(now.getUTCMonth() + 1) +
      "-01";

    ret.push({
      _id: id,
      name: strMonth[now.getUTCMonth()] + " " + String(now.getUTCFullYear()),
    });
  }
  return ret;
}

function getYearOptions(start, end) {
  let ret = [];
  for (let i = start; i <= end; i++) {
    let now = new Date();
    now = new Date(Date.UTC(now.getUTCFullYear() + i, 1, 1));
    let id = twoDigits(now.getUTCFullYear()) + "-01-01";

    ret.push({
      _id: id,
      name: String(now.getUTCFullYear()),
    });
  }
  return ret;
}

function getWeeklyOptions(startDate, endDate) {
  //realign to Sunday
  while (startDate.getUTCDay() !== 0) {
    startDate.setUTCDate(startDate.getUTCDate() + 1);
  }

  let strMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let ret = [];
  while (startDate.getTime() < endDate.getTime()) {
    let sqlStartDate = Date_to_SQL(startDate);
    ret.push({
      _id: sqlStartDate,
      name:
        strMonth[startDate.getUTCMonth()] +
        " " +
        startDate.getUTCDate() +
        ", " +
        startDate.getUTCFullYear(),
    });
    startDate.setUTCDate(startDate.getUTCDate() + 7);
  }
  return ret;
}

function SQL_to_Date(sqlDate) {
  if (sqlDate === undefined) {
    return undefined;
  }
  return new Date(
    Date.UTC(
      parseInt(sqlDate.substring(0, 4)),
      parseInt(sqlDate.substring(5, 7)) - 1,
      parseInt(sqlDate.substring(8, 10))
    )
  );
}

function Date_to_SQL(date) {
  return (
    twoDigits(date.getUTCFullYear()) +
    "-" +
    twoDigits(date.getUTCMonth() + 1) +
    "-" +
    twoDigits(date.getUTCDate())
  );
}

/*
 * from / to format: YYYY-MM or YYYY-MM-DD
 * returns array with entries in YYYY-MM format
 */
function getMonths(from, to) {
  let ret = [];
  from = from.substring(0, 7);
  to = to.substring(0, 7);
  while (from <= to) {
    ret.push(from);
    let m = parseInt(from.substring(5));
    if (m < 9) {
      from = from.substring(0, 5) + "0" + String(m + 1);
    } else if (m < 12) {
      from = from.substring(0, 5) + String(m + 1);
    } else {
      from = String(parseInt(from.substring(0, 4)) + 1) + "-01";
    }
  }
  return ret;
}

export default {
  sqlDateList: sqlDateList,
  SQL_to_MDY: SQL_to_MDY,
  MDY_to_SQL: MDY_to_SQL,
  SQL_to_Date: SQL_to_Date,
  sqltime_to_ustime: sqltime_to_ustime,
  ustime_to_sqltime: ustime_to_sqltime,
  Date_to_SQL: Date_to_SQL,
  getEpoch: getEpoch,
  roundFraction: roundFraction,
  getMonthOptions: getMonthOptions,
  getYearOptions: getYearOptions,
  getWeeklyOptions: getWeeklyOptions,
  twoDigits: twoDigits,
  getMonths: getMonths,
};
