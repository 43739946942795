/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import Validator from "../utils/Validator";
import Form from "./common/form";
import Field from "./common/field";
import { toast } from "react-toastify";
import authService from "../services/authService";
import utils from "../utils/utils";
import draftStorage from "../utils/draftStorage";
import Dialog from "./common/dialog";
import Complete from "./common/complete";

export default class EntryForm extends Form {
  state = {
    data: {},
    globalValidate: false,
    title: "",
    complete: false,
  };

  schema = {
    _id: Validator.string(),
  };

  refs = {};

  setRef = (name, input) => {
    let refs = { ...this.refs };
    refs[name] = input;
    this.refs = refs;
  };

  setFocus() {
    let fieldNames = this.props.service.getFieldNames();
    for (let i = 0; i < fieldNames.length; i++) {
      let fieldName = fieldNames[i];
      let fieldDefinition = this.props.service.getFieldDefinition(fieldName);
      if (
        fieldDefinition.readonly !== true &&
        fieldDefinition.type !== "label" &&
        fieldDefinition.type !== "date" &&
        fieldDefinition.type !== "rows" &&
        fieldDefinition.type !== "column" &&
        fieldDefinition.focus !== false &&
        this.refs[fieldName]
      ) {
        this.refs[fieldName].focus();
        break;
      }
    }
  }

  async componentDidMount() {
    if (this.props.service.initAsync) {
      await this.props.service.initAsync();
    }
    if (this.props.setRef !== undefined) {
      this.props.setRef(this);
    }
    let fieldNames = this.props.service.getFieldNames();
    for (let i = 0; i < fieldNames.length; i++) {
      let fieldName = fieldNames[i];
      let fieldDefinition = this.props.service.getFieldDefinition(fieldName);
      if (fieldDefinition === undefined) {
        console.log("field not found: " + fieldName);
      }
      this.schema[fieldName] = new Validator();
      if (fieldDefinition.required === true) {
        this.schema[fieldName] = Validator.string().required();
      }
      this.schema[fieldName] = this.schema[fieldName].label(
        fieldDefinition.label
      );
    }

    const entryId = this.props.match.params.id;
    if (entryId === "new") {
      let data = draftStorage.getDraft(this.props.service.getForm(), "new");
      if (!data) {
        data = utils.getUrlVars();
        let fieldDefinitions = this.props.service.getFieldDefinitions();
        let keys = Object.keys(fieldDefinitions);
        for (let f = 0; f < keys.length; f++) {
          let initValue = fieldDefinitions[keys[f]].default;
          let name = keys[f];
          if (
            (initValue !== undefined && data[name] === undefined) ||
            data[name] === ""
          ) {
            if (initValue === "@Today") {
              let now = new window.Date();
              let month = now.getUTCMonth() + 1;
              if (month < 10) month = "0" + String(month);
              let day = now.getUTCDate();
              if (day < 10) day = "0" + String(day);
              data[name] = now.getUTCFullYear() + "-" + month + "-" + day;
            } else if (initValue === "@UserID") {
              data[name] = authService.getProfile().token;
            } else {
              data[name] = initValue;
            }
          }
        }
        if (this.props.service.definition.translateData) {
          data = this.props.service.definition.translateData(data);
        }

        if (this.props.initData) {
          data = await this.props.initData(data);
        }
      } else {
        let ago = draftStorage.getModifiedAgo(
          this.props.service.getForm(),
          "new"
        );
        if (
          ago &&
          window.location.href.indexOf("?suppressDraftWarning=true") === -1
        ) {
          Dialog.showMessage(
            "Draft exists for this form",
            "An existing draft for this form was opened. The draft was updated " +
              ago +
              '. To discard this draft, hit the "Cancel" button at the bottom.'
          );
        }
      }

      this.setState(
        {
          data,
          title: this.props.service.getCreateTitle(),
          complete: true,
        },
        () => this.setFocus()
      );
      return;
    }

    const entry = await this.props.service.getAsync(entryId);
    if (!entry)
      return this.props.history.replace(`${process.env.PUBLIC_URL}/not-found`);

    //check if we have a draft
    let data = draftStorage.getDraft(this.props.service.getForm(), entryId);
    if (!data) {
      data = this.mapToViewModel(entry);
    } else {
      let ago = draftStorage.getModifiedAgo(
        this.props.service.getForm(),
        entryId
      );
      if (ago) {
        Dialog.showMessage(
          "Draft exists for this item",
          "An existing draft for this form was opened. The draft was updated " +
            ago +
            '. To discard this draft, hit the "Cancel" button at the bottom.'
        );
      }
    }

    if (this.props.updateData) {
      data = await this.props.updateData(data);
    }

    if (this.props.service.definition.translateData) {
      data = this.props.service.definition.translateData(data);
    }
    this.setState(
      {
        data,
        title: this.props.service.getEditTitle(),
        complete: true,
      },
      () => this.setFocus()
    );
  }

  mapToViewModel(entry) {
    let ret = {
      _id: entry._id,
    };
    let fieldNames = this.props.service.getFieldNames();
    for (let i = 0; i < fieldNames.length; i++) {
      ret[fieldNames[i]] = entry[fieldNames[i]];
    }
    if (entry._json) {
      let keys = Object.keys(entry._json);
      for (let k = 0; k < keys.length; k++) {
        ret[keys[k]] = entry._json[keys[k]];
      }
    }

    if (entry._author) {
      ret._author = entry._author;
    }
    return ret;
  }

  async doSubmit(e, saveAndNew) {
    e.preventDefault();

    const errors = this.validate();

    if (this.props.service) {
      draftStorage.deleteDraft(
        this.props.service.getForm(),
        this.state.data._id
      );
    }

    let hasDateErrors = false;
    if (errors) {
      let keys = Object.keys(errors);
      keys.forEach((key) => {
        let fieldDefinition = this.props.service.getFieldDefinition(key);
        if (
          fieldDefinition.type === "date" ||
          fieldDefinition.type === "time"
        ) {
          hasDateErrors = true;
        }
      });
    }
    if (hasDateErrors && errors && Object.keys(errors).length > 0) {
      let msg = "";
      Object.keys(errors).forEach((error) => {
        if (msg.length > 0) {
          msg += "\n";
        }
        msg += errors[error];
      });
      Dialog.showMessage(
        "Field validation failed",
        msg.split("\n").map((m, index) => (
          <React.Fragment key={index}>
            {index > 0 && <br />}
            {m}
          </React.Fragment>
        ))
      );
      this.setState({ globalValidate: true });
      return;
    }
    this.setState({ globalValidate: false });
    let data = { ...this.state.data };
    let fieldNames = this.props.service.getFieldNames();
    fieldNames.forEach((fieldName) => {
      let fieldDefinition = this.props.service.getFieldDefinition(fieldName);
      if (
        data[fieldName] === undefined ||
        !Validator.isFieldShown(fieldDefinition.showOnly, data)
      ) {
        if (fieldDefinition.type === "number") {
          data[fieldName] = 0;
        } else {
          data[fieldName] = "";
        }
      } else if (fieldDefinition.type === "number") {
        if (typeof data[fieldName] !== "number") {
          data[fieldName] =
            data[fieldName] === "" ? "" : parseInt(data[fieldName]);
        }
      }
      if (fieldDefinition.type === "custom") {
        if (data[fieldName] === undefined || data[fieldName] === "") {
          data[fieldName] = "{}";
        }
      }
      if (fieldDefinition.json === true) {
        if (data._json === undefined) {
          data._json = {};
        }
        data._json[fieldName] = data[fieldName];
        delete data[fieldName];
      }
    });

    let success = await this.props.service.saveAsync(data);
    if (success !== false) {
      toast.success("Save successful");
      if (this.props.history) {
        let redirectAfterSave = this.props.service.definition.redirectAfterSave;
        if (saveAndNew) {
          let url = this.props.service.getBaseUrl() + "/new";
          if (this.props.service.definition.redirectAfterSaveAndNewFields) {
            let newData = {};
            this.props.service.definition.redirectAfterSaveAndNewFields.forEach(
              (fieldName, index) => {
                if (index === 0) {
                  url += "?";
                } else {
                  url += "&";
                }
                url += encodeURIComponent(fieldName) + "=";
                url += encodeURIComponent(data[fieldName]);
                newData[fieldName] = data[fieldName];
              }
            );
            this.setState({ data: newData });
          }
          this.props.history.push(url);
        } else if (redirectAfterSave) {
          if (!redirectAfterSave.startsWith("/")) {
            redirectAfterSave = "/" + redirectAfterSave;
          }
          this.props.history.push(redirectAfterSave);
        } else {
          this.props.history.push(this.props.service.getBaseUrl());
        }
      }
      if (this.props.callback && success) {
        this.props.callback(success);
      }
    } else {
      toast.error("Save failed");
    }
  }

  setData = (data) => {
    let data1 = { ...data };
    this.setState({ data: data1 });
  };

  render() {
    let fieldnames = this.props.service.getFieldNames();
    let fieldOutput = [];
    let columns = undefined;
    for (let i = 0; i < fieldnames.length; i++) {
      let name = fieldnames[i];
      let label = this.props.service.getFieldLabel(name);
      let fieldDefinition = this.props.service.getFieldDefinition(name);
      if (fieldDefinition.required) {
        label += "*";
      }
      let out = undefined;
      if (!Validator.isFieldShown(fieldDefinition.showOnly, this.state.data)) {
        out = undefined;
      } else if (fieldDefinition.type === "column") {
        if (columns === undefined) {
          columns = [];
        }
        if (fieldDefinition.columnEnd) {
          out = (
            <div key={name + "_" + i} className="container">
              <div className="row">
                {columns.map((column, index) => (
                  <div key={index} className="col-md">
                    {column}
                  </div>
                ))}
              </div>
            </div>
          );
          columns = undefined;
        } else {
          columns.push([]);
        }
      } else {
        let field = (
          <Field
            id={this.props.match.params.id}
            data={this.state.data}
            hideLabel={false}
            history={this.props.history}
            key={name}
            label={label}
            name={name}
            onChange={this.handleChange}
            service={this.props.service}
            setRef={this.setRef}
            globalValidate={this.state.globalValidate}
          />
        );
        if (columns === undefined) {
          out = field;
        } else {
          columns[columns.length - 1].push(field);
        }
      }

      if (out !== undefined) {
        fieldOutput.push(out);
      }
    }
    return (
      <div>
        {this.props.callback ? (
          <React.Fragment>
            Note: Scroll dialog content if necessary to expose submit buttons
            <h1>{this.state.title}</h1>
          </React.Fragment>
        ) : (
          <h1 className="d-none d-lg-inline">{this.state.title}</h1>
        )}
        <form
          onSubmit={(e) => {
            this.doSubmit(e, false);
          }}
          autoComplete="off"
        >
          <div>{fieldOutput}</div>
          <div className="btn-toolbar">
            {this.props.service.hasWritePermissions(
              undefined,
              this.state.data._author
            ) ? (
              <React.Fragment>
                {this.renderSubmitButton("Save", false)}&nbsp;
                {this.props.service.definition.redirectAfterSaveAndNewFields &&
                  this.renderSubmitButton("Save & New", true)}
              </React.Fragment>
            ) : null}
            &nbsp;
            {this.renderCancelButton()}
            &nbsp;
            {this.props.match.params.id !== "new" &&
              this.props.service.hasDeletePermissions(
                undefined,
                this.state.data && this.state.data._author
              ) &&
              this.renderDeleteButton(
                this.props.service,
                this.props.match.params.id
              )}
          </div>
        </form>
        {this.state.complete && <Complete />}
      </div>
    );
  }
}
