/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";
import { usersService } from "./usersService";

export const bankAccountsDefinition = {
  baseUrl: "/settings/bankaccounts",
  viewTitle: "Bank Accounts",
  hideNav: true,
  editTitle: "Edit Bank Account",
  createTitle: "Create Bank Account",
  fieldNames: ["name", "options", "user"],
  createPermissions: ["[all]"],
  deletePermissions: ["[all]"],
  readPermissions: ["[all]"],
  writePermissions: ["[all]"],
  fields: {
    name: {
      label: "Name",
      type: "text",
      length: 255,
      required: true,
    },
    options: {
      label: "Options",
      type: "checkbox",
      selections: "showinreceipts|Show in receipts,income|Income",
    },
    user: {
      label: "User",
      type: "select",
      selections: usersService,
      sorted: true,
      required: false,
    },
  },
};

export let bankAccountsService = new EntryService(bankAccountsDefinition);
