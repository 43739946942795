/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import { bankAccountsDefinition } from "./bankAccountsService";
import { budgetDefinition } from "./budgetService";
import { expenseAccountsDefinition } from "./expenseAccountsService";
import { receiptsDefinition } from "./receiptsService";
import { billsDefinition } from "./billsService";
import { usersDefinition } from "./usersService";

export default class GeneratePhpFiles extends Component {
  render() {
    let definitions = [
      bankAccountsDefinition,
      budgetDefinition,
      expenseAccountsDefinition,
      receiptsDefinition,
      billsDefinition,
      usersDefinition,
    ];
    let retError = [];
    return (
      <React.Fragment>
        <h3>sql_schema.inc.php</h3>
        <textArea cols="100" rows="20">
          {"<?php\n" +
            "/* Copyright (C) Andreas Goelzer - All Rights Reserved\n" +
            " * Unauthorized copying of this file, via any medium is strictly prohibited\n" +
            "* Proprietary and confidential\n" +
            "* Written by Andreas Goelzer <agolzer@agolzer.com>, 2019\n" +
            "*\n" +
            "********************************************************\n" +
            "*                                                       \n" +
            "* Autogenerated file. DO NOT MODIFY DIRECTLY            \n" +
            "*                                                       \n" +
            "* Regenerate via http://localhost:3000/generatephpfiles \n" +
            "*                                                       \n" +
            "********************************************************\n" +
            "*/\n" +
            "\n" +
            "function getForms() {\n" +
            "$ret = array();\n"}
          {definitions.map((definition) => {
            let urlParts = definition.baseUrl.split("/");
            let sqlName = urlParts[urlParts.length - 1];
            return '$ret[] = "' + sqlName + '";\n';
          })}
          {"return $ret;\n" +
            "}\n\n" +
            "function getSchema($form) {\n" +
            "global $createPermissions;\n" +
            "global $deletePermissions;\n" +
            "global $readPermissions;\n" +
            "global $writePermissions;\n" +
            "global $columns;\n" +
            "global $baseurl;\n" +
            "$columns = array();\n" +
            '$form = $_GET["form"];\n' +
            'if($form === "") {\n' +
            '    die("form parameter missing");\n'}
          {definitions.map((definition) => {
            let baseUrl = definition.baseUrl.replace(/^\//g, "");
            let urlParts = definition.baseUrl.split("/");
            let sqlName = urlParts[urlParts.length - 1];
            let ret = [];
            ret.push('} else if($form === "' + sqlName + '"){\n');

            let createPermissions = definition.createPermissions || ["[all]"];
            for (let cp = 0; cp < createPermissions.length; cp++) {
              ret.push(
                '    $createPermissions[] = "' + createPermissions[cp] + '";\n'
              );
            }

            let deletePermissions = definition.deletePermissions || [
              "[author]",
            ];
            for (let dp = 0; dp < deletePermissions.length; dp++) {
              ret.push(
                '      $deletePermissions[] = "' +
                  deletePermissions[dp] +
                  '";\n'
              );
            }

            let readPermissions = definition.readPermissions || ["[all]"];
            for (let rp = 0; rp < readPermissions.length; rp++) {
              ret.push(
                '    $readPermissions[] = "' + readPermissions[rp] + '";\n'
              );
            }

            let writePermissions = definition.writePermissions || ["sysadmin"];
            for (let wp = 0; wp < writePermissions.length; wp++) {
              ret.push(
                '    $writePermissions[] = "' + writePermissions[wp] + '";\n'
              );
            }

            let hasJson = false;
            let keys = Object.keys(definition.fields);
            for (let k = 0; k < keys.length; k++) {
              let field = definition.fields[keys[k]];
              if (
                field.type !== "label" &&
                field.type !== "calculated" &&
                field.type !== "workflow" &&
                ((field.type !== "custom" && field.type !== "column") ||
                  keys[k] === "metadata")
              ) {
                if (field.json === true) {
                  hasJson = true;
                } else {
                  let def = "";
                  if (
                    field.selections &&
                    field.selections.isService &&
                    field.selections.isService()
                  ) {
                    let parts = field.selections.getBaseUrl().split("/");
                    def = "lookup:" + parts[parts.length - 1];
                  } else if (
                    field.type === "date" ||
                    field.type === "month" ||
                    field.type === "year"
                  ) {
                    def = "date";
                  } else if (field.type === "number") {
                    def = "number";
                  } else if (field.type === "rows") {
                    def = "metadata";
                  }
                  ret.push(
                    '    $columns["' + keys[k] + '"] = "' + def + '";\n'
                  );
                }
              }
            }
            if (hasJson) {
              ret.push('    $columns["_json"] = "metadata";\n');
            }
            if (definition.workflow !== undefined) {
              ret.push('    $columns["_workflow"] = "workflow";\n');
            }
            ret.push('    $baseurl = "' + baseUrl + '";\n');
            return ret;
          })}
          {"} else {\n"}
          {'    die("form parameter invalid");\n'}
          {"}\n"}
          {"}\n"}
          {"?>\n"}
        </textArea>

        <br />
        {retError.map((error) => (
          <b>
            {error}
            <br />
          </b>
        ))}
      </React.Fragment>
    );
  }
}
