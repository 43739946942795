/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";
import { budgetItemService } from "./budgetItemService";

//pass in entire data set and it only returns data.activity (values to be shown) as an array
let customBudgetAccounts = (data, name) => {
  if (data[name] && typeof data[name] === "string") {
    data[name] = JSON.parse(data[name]);
  }
  return data[name];
};

export const budgetDefinition = {
  baseUrl: "/budget",
  viewTitle: "Budget",
  editTitle: "Edit Budget",
  createTitle: "Create Budget",
  fieldNames: ["month", "budgetAccounts"],
  columnNames: ["month"],
  createPermissions: ["[all]"],
  deletePermissions: ["[all]"],
  readPermissions: ["[all]"],
  writePermissions: ["[all]"],
  fields: {
    month: {
      label: "Month",
      type: "date",
      required: true,
    },
    budgetAccounts: {
      label: "Budget",
      type: "rows",
      service: budgetItemService,
      customview: customBudgetAccounts,
    },
  },
};

export let budgetService = new EntryService(budgetDefinition);
