/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import { billsService } from "../services/billsService";
import { receiptsService } from "../services/receiptsService";
import Complete from "./common/complete";
import { budgetService } from "../services/budgetService";
import dateUtils from "../utils/dateUtils";
import { expenseAccountsService } from "../services/expenseAccountsService";
import Currency from "../utils/currency";

export default class HomePage extends Component {
  state = {
    bills: [],
    months: [],
    expenseAccounts: {},
    expenseAccountsList: [],
    endingBalance: {},
    selectedMonth: this.lastMonth(),
    budgets: undefined,
    showMore: false,
    showDetails: {},
  };

  lastMonth() {
    return dateUtils.Date_to_SQL(new Date()).substring(0, 7);
  }

  getPreviousMonth(month) {
    month = month.substring(0, 7);
    let m = parseInt(month.substring(5));
    let ret = "";
    if (m === 1) {
      ret = String(parseInt(month.substring(0, 4)) - 1) + "-12";
    } else if (m > 10) {
      ret = month.substring(0, 5) + String(m - 1);
    } else {
      ret = month.substring(0, 5) + "0" + String(m - 1);
    }
    return ret;
  }

  async componentDidMount() {
    let billsDb = await billsService.getAllAsyncLookup();
    let expenseAccountsList = await expenseAccountsService.getAllAsync();
    let budgetsDb = await budgetService.getAllAsync();

    let bills = [...billsDb];
    bills = this.expandSplits(bills);
    bills.sort((a, b) => (a.date < b.date ? -1 : a.date > b.date ? 1 : 0));

    //get all relevant months
    let months = [];
    if (bills.length > 0) {
      months = dateUtils.getMonths(bills[0].date, bills[bills.length - 1].date);
    }
    let selectedMonth = months[months.length - 1];

    let budgets = {};
    let endingBalance = {};
    months.forEach((m) => {
      endingBalance[m] = {};
      budgets[m] = {};
    });

    let expenseAccounts = {};
    expenseAccountsList.forEach((ea) => {
      ea.forReceipts =
        ("," + ea.options + ",").indexOf(",showinreceipts,") !== -1;
      expenseAccounts[ea._id] = ea;
      months.forEach((m) => {
        endingBalance[m][ea._id] = new Currency();
        budgets[m][ea._id] = new Currency();
      });
    });
    expenseAccountsList.sort((a, b) =>
      a.name < b.name ? -1 : a.name > b.name ? 1 : 0
    );

    budgetsDb.forEach((b) => {
      let month = b.month.substring(0, 7);
      b.budgetAccounts.forEach((ea) => {
        budgets[month][ea.expenseAccount].add(parseFloat(ea.amount));
      });
    });

    let lastBudget = {};
    months.forEach((m) => {
      Object.keys(expenseAccounts).forEach((ea) => {
        if (lastBudget[ea] === undefined) {
          lastBudget[ea] = new Currency(0);
        }
        if (budgets[m][ea].getValue() === 0) {
          budgets[m][ea].set(lastBudget[ea].getValue());
        } else {
          lastBudget[ea].set(budgets[m][ea].getValue());
        }
        endingBalance[m][ea].add(budgets[m][ea].getValue());
      });
    });

    bills.forEach((r) => {
      let month = r.date.substring(0, 7);
      let ea = r.expenseAccount[0]._id;
      endingBalance[month][ea].sub(r.amount);
    });

    months.forEach((month) =>
      Object.keys(expenseAccounts).forEach((ea) => {
        let prevMonth = this.getPreviousMonth(month);
        if (
          endingBalance[prevMonth] !== undefined &&
          endingBalance[prevMonth][ea] !== undefined
        ) {
          endingBalance[month][ea].add(endingBalance[prevMonth][ea].getValue());
        }
      })
    );

    this.setState({
      bills,
      expenseAccounts,
      expenseAccountsList,
      endingBalance,
      budgets,
      months,
      selectedMonth,
    });
  }

  expandSplits(bills) {
    let ret = [];
    bills.forEach((b) => {
      let didItemize = false;
      if (b.itemized && b.itemized.length > 0) {
        let total = new Currency();
        b.itemized.forEach((item) => total.add(item.amount));
        if (String(total.getValue()) === String(b.amount)) {
          b.itemized.forEach((item) => {
            let item2 = { ...b };
            delete item2.itemized;
            item2.stmt_desc = "";
            item2.description = "ITEM: " + item.description;
            item2.amount = new Currency(item.amount);
            item2.expenseAccount = [
              { _id: item.expenseAccount, name: item.expenseAccount },
            ];
            if (item.amount !== "") {
              ret.push(item2);
              didItemize = true;
            }
          });
        }
      }
      if (!didItemize) {
        ret.push(b);
      }
    });
    return ret;
  }

  render() {
    if (!this.state.budgets) {
      return <h2>Loading...</h2>;
    }
    return (
      <React.Fragment>
        <b>
          Remaining Funds for{" "}
          <select
            value={this.state.selectedMonth}
            onChange={({ currentTarget: input }) =>
              this.setState({
                selectedMonth: input.value,
              })
            }
          >
            {[...this.state.months].reverse().map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
          :
        </b>
        {this.state.expenseAccountsList
          .filter((ea) => ea.forReceipts)
          .map((ea) => (
            <React.Fragment key={ea._id}>
              {this.renderBudgetAccount(ea)}
              {this.renderBudgetDetails(ea)}
            </React.Fragment>
          ))}
        <div>&nbsp;</div>
        {this.state.showMore &&
          this.state.expenseAccountsList
            .filter((ea) => !ea.forReceipts)
            .map((ea) => (
              <React.Fragment key={ea._id}>
                {this.renderBudgetAccount(ea)}
                {this.renderBudgetDetails(ea)}
              </React.Fragment>
            ))}
        {!this.state.showMore && (
          <div
            className="roundButton"
            onClick={() => this.setState({ showMore: true })}
          >
            Show More
          </div>
        )}
        {this.renderTotals()}
        <Complete />
      </React.Fragment>
    );
  }

  renderBudgetAccount(ea) {
    let endingBalance = new Currency();
    if (this.state.endingBalance[this.state.selectedMonth]) {
      endingBalance =
        this.state.endingBalance[this.state.selectedMonth][ea._id];
    }
    let budget = new Currency();
    if (this.state.budgets[this.state.selectedMonth]) {
      budget = this.state.budgets[this.state.selectedMonth][ea._id];
    }
    let percent;
    if (endingBalance.getValue() >= 0) {
      let used = new Currency(budget.getValue()).sub(endingBalance.getValue());
      percent = String((used.getValue() * 100) / budget.getValue()) + "%";
    } else {
      let used = new Currency(budget.getValue()).add(endingBalance.getValue());
      percent = String((used.getValue() * 100) / budget.getValue()) + "%";
    }
    return (
      <div key={ea._id} style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{ display: "flex", flex: "1 1 auto", position: "relative" }}
          className={
            endingBalance.getValue() >= 0
              ? "roundButton"
              : "roundButton redBackground"
          }
          onClick={() => {
            let showDetails = { ...this.state.showDetails };
            showDetails[ea._id] = !showDetails[ea._id];
            this.setState({ showDetails });
          }}
        >
          <div
            style={{
              display: "",
              background: "#EEEEEE",
              width: percent,
              position: "relative",
              zIndex: 1,
              borderRadius: "25px 0px 0px 25px",
            }}
          >
            &nbsp;
          </div>
          <div
            style={{
              padding: "0 0 0 5px",
              display: "flex",
              flex: "1 1 auto",
              zIndex: 5,
              position: "absolute",
            }}
          >
            {ea.name}
          </div>
          <div
            className="container"
            style={{
              paddingRight: "5px",
              textAlign: "right",
              zIndex: 5,
              position: "absolute",
            }}
          >
            {endingBalance.toString()}
          </div>
        </div>
        <div
          className="roundButton grayBackground"
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
          onClick={() =>
            this.props.history.push(
              receiptsService.getBaseUrl() + "/new?expenseAccount=" + ea._id
            )
          }
        >
          +
        </div>
      </div>
    );
  }

  renderBudgetDetails(ba) {
    if (!this.state.showDetails[ba._id]) {
      return null;
    }
    let items = this.state.bills
      .filter((r) => r.expenseAccount[0]._id === ba._id)
      .filter((r) => r.date.substring(0, 7) === this.state.selectedMonth)
      .map((r) => {
        return (
          <tr key={r._id}>
            <td>
              <a href={"/bills/" + r._id}>{r.date}</a>
            </td>
            <td>{new Currency(-r.amount).toString()}</td>
            <td>{r.description + " / " + r.stmt_desc}</td>
            <td>{r.paymenttype[0].name}</td>
          </tr>
        );
      });

    let endingBalance = (
      <tr key="total">
        <td>Ending Balance</td>
        <td>
          {this.state.endingBalance[this.state.selectedMonth][
            ba._id
          ].toString()}
        </td>
        <td>&nbsp;</td>
      </tr>
    );
    let previousTotal = new Currency();
    if (
      this.state.endingBalance[
        this.getPreviousMonth(this.state.selectedMonth)
      ] !== undefined
    ) {
      previousTotal =
        this.state.endingBalance[
          this.getPreviousMonth(this.state.selectedMonth)
        ][ba._id];
    }
    let beginningBalance = (
      <tr key="beginningBalance">
        <td>Beginning Balance</td>
        <td>{previousTotal.toString()}</td>
        <td>&nbsp;</td>
      </tr>
    );
    let budget = (
      <tr key="budget">
        <td>Budget</td>
        <td>
          {this.state.budgets[this.state.selectedMonth][ba._id].toString()}
        </td>
        <td>&nbsp;</td>
      </tr>
    );
    let sTotal = new Currency();
    sTotal.add(
      this.state.endingBalance[this.state.selectedMonth][ba._id].getValue()
    );
    sTotal.sub(previousTotal.getValue());
    sTotal.sub(this.state.budgets[this.state.selectedMonth][ba._id].getValue());
    let subtotal = (
      <tr key="subtotal">
        <td>Subtotal</td>
        <td>{sTotal.toString()}</td>
        <td>&nbsp;</td>
      </tr>
    );

    return (
      <table border="1">
        <tbody>
          {beginningBalance}
          {items}
          {subtotal}
          {budget}
          {endingBalance}
        </tbody>
      </table>
    );
  }

  renderTotals() {
    const INCOME_ID =
      "967966447b7ef9fe2477ca2fe2a83363d1a5e0c664062d805f8dcc35ecf5ee31";
    let budgetsSelectedMonth =
      this.state.budgets[this.state.selectedMonth] || [];
    let billsSelectedMonth = this.state.bills.filter(
      (r) => r.date.substring(0, 7) === this.state.selectedMonth
    );

    let budgetTotal = new Currency();
    Object.keys(budgetsSelectedMonth).forEach(
      (ba) =>
        ba !== INCOME_ID && budgetTotal.add(budgetsSelectedMonth[ba].getValue())
    );

    let billsTotal = new Currency();
    billsSelectedMonth.forEach(
      (r) =>
        r.expenseAccount[0]._id !== INCOME_ID &&
        billsTotal.sub(parseFloat(r.amount))
    );

    return (
      <table border="1">
        <tbody>
          <tr key="labelTotal">
            <td colSpan="2">Month Totals</td>
          </tr>
          <tr key="billsUI">
            <td>Expenses</td>
            <td>{billsTotal.toString()}</td>
          </tr>
          <tr key="budgetUI">
            <td>Budget</td>
            <td>{budgetTotal.toString()}</td>
          </tr>
          <tr key="winlossUI">
            <td>
              {budgetTotal.getValue() + billsTotal.getValue >= 0
                ? "Win"
                : "Loss"}
            </td>
            <td>
              {new Currency()
                .add(budgetTotal.getValue())
                .add(billsTotal.getValue())
                .toString()}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
