/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import axios from "axios";
import decode from "jwt-decode";
import utils from "../utils/utils";

async function login(email, password) {
  try {
    let res = await axios.post(utils.getDbUrl() + "login.php", {
      email: email,
      password: password,
    });
    if (res.data && res.data.token) {
      setToken(res.data.token);
    } else if (res.data && res.data.error) {
      return res.data.error;
    } else {
      return null;
    }
  } catch (e) {
    console.log("CATCH", e);
  }
}

async function isValidEmail(email) {
  try {
    let res = await axios.post(utils.getDbUrl() + "login.php", {
      checkemail: email,
    });
    if (res.data && res.data.error) {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    console.log("CATCH", e);
  }
  return false;
}

async function loginSession(sessionid) {
  try {
    let res = await axios.post(utils.getDbUrl() + "session.php", {
      sessionid: sessionid,
    });
    if (res.data && res.data.token) {
      setToken(res.data.token);
    } else if (res.data && res.data.error) {
      return res.data.error;
    } else {
      return null;
    }
  } catch (e) {
    console.log("CATCH", e);
  }
}

async function changePassword(password, sessionid) {
  try {
    let res = await axios.post(utils.getDbUrl() + "session.php", {
      sessionid: sessionid,
      password: password,
    });
    if (res.data && res.data.token) {
      setToken(res.data.token);
    } else if (res.data && res.data.error) {
      return res.data.error;
    } else {
      return null;
    }
  } catch (e) {
    console.log("CATCH", e);
  }
}

async function resetPassword(email) {
  try {
    let res = await axios.post(utils.getDbUrl() + "login.php", {
      resetemail: email,
    });
    if (res.data && res.data.error) {
      return res.data.error;
    } else if (res.data && res.data.msg) {
      return res.data.msg;
    } else {
      return null;
    }
  } catch (e) {
    console.log("CATCH", e);
  }
}

const ID_TOKEN = "id_token_financial";

function setToken(idToken) {
  // Saves user token to localStorage
  localStorage.setItem(ID_TOKEN, idToken);
}

function getToken() {
  // Retrieves the user token from localStorage
  return localStorage.getItem(ID_TOKEN);
}

function logout() {
  // Clear user token and profile data from localStorage
  localStorage.removeItem(ID_TOKEN);
}

function getProfile() {
  let token = getToken();
  if (token === null) {
    return undefined;
  }
  let ret = decode(getToken());
  return JSON.parse(ret);
}

function getUsername() {
  let profile = getProfile();
  if (profile === undefined) {
    return undefined;
  } else {
    return profile.name; //"Andreas Goelzer";
  }
}

function getEmail() {
  let profile = getProfile();
  if (profile === undefined) {
    return undefined;
  } else {
    return profile.email; //"agolzer@agolzer.com";
  }
}

function getGroups() {
  return ["[all]"];
}

function hasGroup(group) {
  return true;
}

export default {
  getUsername,
  getEmail,
  isValidEmail,
  logout,
  login,
  loginSession,
  changePassword,
  resetPassword,
  getProfile,
  getToken,
  getGroups,
  hasGroup,
};
