/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";

export const expenseAccountsDefinition = {
  baseUrl: "/settings/expenseaccounts",
  viewTitle: "Expense Accounts",
  hideNav: true,
  editTitle: "Edit Expense Account",
  createTitle: "Create Expense Account",
  fieldNames: ["name", "options"],
  createPermissions: ["[all]"],
  deletePermissions: ["[all]"],
  readPermissions: ["[all]"],
  writePermissions: ["[all]"],
  fields: {
    name: {
      label: "Name",
      type: "text",
      length: 255,
      required: true,
    },
    options: {
      label: "Options",
      type: "checkbox",
      selections: "showinreceipts|Show in receipts,income|Income",
    },
  },
};

export let expenseAccountsService = new EntryService(expenseAccountsDefinition);
