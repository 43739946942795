/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import axios from "axios";
import authService from "./authService";
import utils from "../utils/utils";

export default class EntryService {
  definition = {};

  phpfile = "";

  constructor(definition) {
    this.definition = definition;
    this.phpfile = utils.getDbUrl() + "sql.php?form=" + this.getSqlTable();
  }

  async getAllAsync() {
    let res = await axios.get(this.phpfile, {
      headers: { "X-Auth-Token": authService.getToken() },
    });
    if (res.data !== null) {
      Object.keys(res.data).forEach((key) => {
        if (res.data[key] === null) {
          res.data[key] = "";
        }
      });
    }
    return res.data;
  }

  async getAllAsyncLookup() {
    let res = await axios.get(this.phpfile + "&dolookup=true", {
      headers: { "X-Auth-Token": authService.getToken() },
    });
    if (res.data !== null) {
      Object.keys(res.data).forEach((key) => {
        if (res.data[key] === null) {
          res.data[key] = "";
        }
      });
    }
    return res.data;
  }

  async getAsync(id) {
    let res = await axios.get(this.phpfile + "&id=" + id, {
      headers: { "X-Auth-Token": authService.getToken() },
    });
    if (res.data != null) {
      Object.keys(res.data).forEach((key) => {
        if (res.data[key] === null) {
          res.data[key] = "";
        }
      });
    }
    return res.data;
  }

  async saveAsync(entry) {
    let entryInDb = {};
    let fieldNames = [...this.definition.fieldNames, "_json"];
    for (let i = 0; i < fieldNames.length; i++) {
      if (entry[fieldNames[i]] !== undefined) {
        if (typeof entry[fieldNames[i]] === "object") {
          entryInDb[fieldNames[i]] = JSON.stringify(entry[fieldNames[i]]);
        } else {
          let type = this.definition.fields[fieldNames[i]].type || "text";
          if (type === "currency" && entry[fieldNames[i]] === "") {
            entryInDb[fieldNames[i]] = "0.00";
          } else if (type === "miles" && entry[fieldNames[i]] === "") {
            entryInDb[fieldNames[i]] = "0.0";
          } else if (type === "rows" && entry[fieldNames[i]] === "") {
            entryInDb[fieldNames[i]] = "[]";
          } else if (type === "number" && !entry[fieldNames[i]]) {
            entryInDb[fieldNames[i]] = "";
          } else {
            entryInDb[fieldNames[i]] = entry[fieldNames[i]];
          }
        }
      }
    }
    entryInDb._id = entry._id;
    if (entry._workflow) {
      entryInDb._workflow = JSON.stringify(entry._workflow);
    }

    let ret = await axios.post(this.phpfile, entryInDb, {
      headers: { "X-Auth-Token": authService.getToken() },
      data: entryInDb,
    });

    if (ret.status !== 200 && ret.status !== 201) {
      return false;
    }
    if (ret.data._error !== undefined) {
      return false;
    }
    let data = ret.data;
    if (typeof data === "string") {
      data = JSON.parse(data);
    }
    return data._id;
  }

  async deleteAsync(id) {
    let ret = await axios.delete(this.phpfile + "&id=" + id, {
      headers: { "X-Auth-Token": authService.getToken() },
    });
    if (ret.status !== 200) {
      return false;
    }
    if (ret.data._error !== undefined) {
      return false;
    }
    return true;
  }

  getBaseUrl = () => {
    return this.getPublicUrl() + this.definition.baseUrl;
  };

  getForm() {
    let form = this.getBaseUrl();
    let lastSlash = form.indexOf("/");
    if (lastSlash !== -1) {
      form = form.substring(lastSlash + 1);
    }
    return form;
  }

  getSqlTable = () => {
    if (this.definition.sqlUrl !== undefined) {
      let parts = this.definition.sqlUrl.split("/");
      return parts[parts.length - 1];
    } else {
      let parts = this.definition.baseUrl.split("/");
      return parts[parts.length - 1];
    }
  };

  getPublicUrl = () => {
    return `${process.env.PUBLIC_URL}`;
  };

  getFieldNames = () => {
    return this.definition.fieldNames;
  };

  getColumnNames = () => {
    return this.definition.columnNames || this.definition.fieldNames;
  };

  getFieldLabel = (field) => {
    if (this.definition.fields[field] === undefined) {
      console.log("getfieldlabel", field, this.definition.fields);
    }
    return this.definition.fields[field].label;
  };

  getFieldStyle = (field) => {
    return this.definition.fields[field].style;
  };

  getFieldDisplay = (field) => {
    return this.definition.fields[field].display;
  };

  getEditTitle = () => {
    return this.definition.editTitle;
  };

  getCreateTitle = () => {
    return this.definition.createTitle;
  };

  getViewTitle = () => {
    return this.definition.viewTitle;
  };

  getFieldDefinition = (field) => {
    return this.definition.fields[field];
  };

  getFieldDefinitions = () => {
    return this.definition.fields;
  };

  getWorkflow = () => {
    return this.definition.workflow;
  };

  hasWorkflow = () => {
    return this.getWorkflow() !== undefined;
  };

  isService = () => {
    return true;
  };

  /* type = "read", "write", "create", "delete" */
  getPermissions = (fieldName, type) => {
    if (
      type !== "read" &&
      type !== "write" &&
      type !== "create" &&
      type !== "delete"
    ) {
      return [];
    }
    let field = this.getFieldDefinition(fieldName);
    let permissions = field && field[type + "Permissions"];
    if (permissions === undefined) {
      permissions = this.definition[type + "Permissions"];
      if (permissions === undefined) {
        permissions = ["sysadmin"];
      }
    }
    return permissions;
  };

  getWritePermissions = (fieldName) => {
    return this.getPermissions(fieldName, "write");
  };

  getReadPermissions = (fieldName) => {
    return this.getPermissions(fieldName, "read");
  };

  getCreatePermissions = (fieldName) => {
    if (this.definition.createPermissions) {
      return this.getPermissions(fieldName, "create");
    } else {
      return this.getPermissions(fieldName, "write");
    }
  };

  getDeletePermissions = (fieldName) => {
    if (this.definition.deletePermissions) {
      return this.getPermissions(fieldName, "delete");
    } else {
      return this.getPermissions(fieldName, "write");
    }
  };

  hasPermissions = (name, docAuthor, type) => {
    if (
      type !== "read" &&
      type !== "write" &&
      type !== "create" &&
      type !== "delete"
    ) {
      return [];
    }

    let permissions = this.getPermissions(name, type);
    if (permissions) {
      let groups = authService.getGroups();
      for (let wp = 0; wp < permissions.length; wp++) {
        for (let g = 0; g < groups.length; g++) {
          if (groups[g].toLowerCase() === permissions[wp].toLowerCase()) {
            return true;
          } else if (
            permissions[wp] === "[author]" &&
            (docAuthor === undefined ||
              authService.getProfile().token === docAuthor)
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  hasWritePermissions = (name, docAuthor) => {
    return this.hasPermissions(name, docAuthor, "write");
  };

  hasReadPermissions = (name, docAuthor) => {
    return this.hasPermissions(name, docAuthor, "read");
  };

  hasCreatePermissions = (name, docAuthor) => {
    return this.hasPermissions(name, docAuthor, "create");
  };

  hasDeletePermissions = (name, docAuthor) => {
    return this.hasPermissions(name, docAuthor, "delete");
  };
}
