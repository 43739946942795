/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import Form from "./common/form";
import EntryForm from "./entryForm";
import { budgetService } from "../services/budgetService";
import { expenseAccountsService } from "../services/expenseAccountsService";

export default class BudgetForm extends Form {
  initUpdateData = async (data) => {
    let expenseAccounts = await expenseAccountsService.getAllAsync();
    expenseAccounts.sort((a, b) =>
      a.name < b.name ? -1 : a.name > b.name ? 1 : 0
    );
    if (data.budgetAccounts === undefined) {
      let budgetAccounts = [];
      expenseAccounts.forEach((ea) => {
        budgetAccounts.push({
          _id: ea._id,
          expenseAccount: ea._id,
          amount: 0,
        });
      });
      data.budgetAccounts = budgetAccounts;
    } else {
      let lookupBA = {};
      data.budgetAccounts.forEach((ba) => {
        lookupBA[ba.expenseAccount] = ba;
      });
      let budgetAccounts = [];
      expenseAccounts.forEach((ea) => {
        if (lookupBA[ea._id]) {
          budgetAccounts.push(lookupBA[ea._id]);
        } else {
          budgetAccounts.push({
            _id: ea._id,
            expenseAccount: ea._id,
            amount: "0.00",
          });
        }
      });
      data.budgetAccounts = budgetAccounts;
    }

    return data;
  };

  render() {
    return (
      <EntryForm
        service={budgetService}
        match={this.props.match}
        history={this.props.history}
        initData={this.initUpdateData}
        updateData={this.initUpdateData}
      />
    );
  }
}
