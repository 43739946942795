/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import Table from "./common/table";
import dateUtils from "../utils/dateUtils";
import authService from "../services/authService";

export default class RowEntriesTable extends Component {
  state = {
    selections: {},
  };

  async componentDidMount() {
    const { data } = this.props;
    if (data === undefined) {
      return;
    }

    let selections = {};
    for (let i = 0; i < data.length; i++) {
      let keys = Object.keys(data[i]);
      for (let k = 0; k < keys.length; k++) {
        let key = keys[k];
        let definition = this.props.service.getFieldDefinition(key);
        if (definition && definition.selections !== undefined) {
          if (typeof definition.selections === "string") {
            let values = definition.selections.split(",");
            let dataSelections = [];
            for (let d = 0; d < values.length; d++) {
              dataSelections.push({ id: values[d], name: values[d] });
            }
            selections[key] = dataSelections;
          } else if (
            definition.selections.isService &&
            definition.selections.isService()
          ) {
            selections[key] = await definition.selections.getAllAsyncLookup();
          } else {
            selections[key] = [];
            let groups = authService.getGroups();
            for (let g = 0; g < groups.length; g++) {
              if (definition.selections[groups[g]] !== undefined) {
                let values = definition.selections[groups[g]].split(",");
                for (let v = 0; v < values.length; v++) {
                  let value = values[v].split("|", 2);
                  selections[key].push({
                    _id: value[0],
                    name: value[value.length - 1],
                  });
                }
              }
            }
          }
          if (
            selections[key].length === 0 &&
            definition.selections.default !== undefined
          ) {
            let values = definition.selections.default.split(",");
            for (let v = 0; v < values.length; v++) {
              let value = values[v].split("|", 2);
              selections[key].push({
                _id: value[0],
                name: value[value.length - 1],
              });
            }
          }
        }
      }
    }
    this.setState({ selections });
  }

  render() {
    let service = this.props.service;
    let columns = [];
    let columnNames = service.getColumnNames();
    let firstColumn = true;
    for (let i = 0; i < columnNames.length; i++) {
      let label = service.getFieldLabel(columnNames[i]);
      if (service.getFieldDefinition(columnNames[i]).required === true) {
        label += "*";
      }
      let column = {
        path: columnNames[i],
        label: label,
        display: service.getFieldDisplay(columnNames[i]),
      };
      let fieldDefinition = service.getFieldDefinition(columnNames[i]);
      if (
        fieldDefinition.type === undefined ||
        fieldDefinition.type !== "label"
      ) {
        if (firstColumn === true) {
          firstColumn = false;
          column.content = (entry) => entry[columnNames[i]];
        }
        columns.push(column);
      }
    }

    const { data } = this.props;
    if (data === undefined) {
      return null;
    }

    let data2 = [];
    for (let i = 0; i < data.length; i++) {
      data2[i] = { ...data[i] };
      let keys = service.getColumnNames();
      for (let k = 0; k < keys.length; k++) {
        let key = keys[k];
        let definition = service.getFieldDefinition(key);
        if (definition.json === true) {
          data2[i][key] = data2[i]._json[key];
        }
        if (definition && definition.selections !== undefined) {
          if (
            definition.selections.isService &&
            definition.selections.isService()
          ) {
            let datas = data2[i][key];
            if (datas === undefined) {
              datas = [];
            }
            if (!typeof datas === "string") {
              let out = "";
              for (let s = 0; s < datas.length; s++) {
                if (s > 0) {
                  out += ",";
                }
                if (datas && datas[s] && datas[s].name) {
                  out += datas[s].name;
                } else {
                  out += "undefined";
                }
              }
              data2[i][key] = out;
            }
          }
        } else if (definition && definition.type === "date") {
          data2[i][key] = dateUtils.SQL_to_MDY(data2[i][key]);
        } else if (definition && definition.type === "calculated") {
          data2[i][key] = definition.value(data2[i], key);
        }
      }
    }

    return (
      <Table
        columns={columns}
        fullData={this.props.fullData}
        data={data2}
        service={service}
        totalsColumns={this.props.totalsColumns}
        onChange={this.props.onChange}
        globalValidate={this.props.globalValidate}
      />
    );
  }
}
