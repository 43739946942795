/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";
import { expenseAccountsService } from "./expenseAccountsService";
import { bankAccountsService } from "./bankAccountsService";
import { billsItemService } from "./billsItemService";

let customBillsItems = (data, name) => {
  if (data[name] && typeof data[name] === "string") {
    data[name] = JSON.parse(data[name]);
  } else if (!data[name]) {
    data[name] = [];
  }
  let addEmpty = true;
  data[name].forEach((item) => {
    if (!item.expenseAccount && !item.amount) {
      addEmpty = false;
    }
  });
  if (addEmpty) {
    data[name].push({
      row: data[name].length + 1,
      _id: data[name].length + 1,
      expenseAccount: "",
      amount: "",
    });
  }
  return data[name];
};

export const billsDefinition = {
  baseUrl: "/bills",
  viewTitle: "Bills",
  editTitle: "Edit Bills",
  createTitle: "Create Bills",
  columnNames: [
    "date",
    "transactionDate",
    "amount",
    "expenseAccount",
    "paymenttype",
    "calc_description",
  ],
  totalsColumns: ["amount"],
  fieldNames: [
    "column1",
    "date",
    "column2",
    "transactionDate",
    "columnEnd",
    "expenseAccount",
    "amount",
    "paymenttype",
    "description",
    "stmt_desc",
    "itemized",
  ],
  createPermissions: ["[all]"],
  deletePermissions: ["[all]"],
  readPermissions: ["[all]"],
  writePermissions: ["[all]"],
  redirectAfterSave: "/home",
  redirectAfterSaveAndNewFields: [
    "date",
    "transactionDate",
    "expenseAccount",
    "paymenttype",
  ],
  fields: {
    column1: {
      type: "column",
    },
    date: {
      label: "Date",
      type: "date",
      required: true,
      default: "@Today",
    },
    column2: {
      type: "column",
    },
    transactionDate: {
      label: "Transaction Date",
      type: "date",
      required: false,
      default: "@Today",
    },
    columnEnd: {
      type: "column",
      columnEnd: true,
    },
    amount: {
      label: "Total Amount",
      type: "currency",
      required: true,
    },
    paymenttype: {
      label: "Payed with",
      type: "select",
      selections: bankAccountsService,
      sorted: true,
      required: true,
    },
    expenseAccount: {
      label: "Expense Account",
      type: "select",
      selections: expenseAccountsService,
      sorted: true,
      required: true,
      focus: false,
    },
    description: {
      label: "Description",
      type: "text",
      length: 255,
      required: false,
    },
    stmt_desc: {
      label: "Statement Desc",
      type: "text",
      length: 255,
      required: false,
    },
    calc_description: {
      label: "Description",
      type: "calculated",
      value: (data) =>
        data.description
          ? data.description + " / " + data.stmt_desc
          : data.stmt_desc,
    },
    itemized: {
      label: "Itemized",
      type: "rows",
      service: billsItemService,
      customview: customBillsItems,
      showOnly:
        "expenseAccount:670f3090982594b1b070ca051ccbf8578cc8e70a7a4f10a3e0723891a1f021fd",
    },
  },
};

export let billsService = new EntryService(billsDefinition);
