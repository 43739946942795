/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import Field from "./field";

export default class TableBody extends Component {
  renderCell = (fullData, rowid, rowData, column, onChange, service) => {
    let readOnly = false;

    if (service && !readOnly) {
      let fieldDefinition = service.getFieldDefinition(column.path);
      if (fieldDefinition.readonly) {
        readOnly = true;
      } else {
        readOnly = !service.hasWritePermissions(
          column.name,
          fullData && fullData._author
        );
      }
    }

    if (onChange) {
      return (
        <Field
          name={column.path}
          id={column.path}
          data={rowData}
          hideLabel={true}
          className="form-control"
          service={service}
          readOnly={readOnly}
          onChange={(input) => {
            onChange(
              rowid,
              input.currentTarget.name,
              input.currentTarget.value
            );
          }}
          globalValidate={this.props.globalValidate}
        />
      );
    }
    if (column.content) {
      return column.content(rowData);
    }

    let ret = undefined;
    if (!rowData[column.path] || typeof rowData[column.path] !== "string") {
      ret = rowData[column.path];
    } else {
      ret =
        rowData[column.path] &&
        rowData[column.path].split("\n").map((v, index) => (
          <React.Fragment key={index}>
            {index > 0 && <br />}
            {v}
          </React.Fragment>
        ));
    }
    if (rowData["_link_" + column.path]) {
      let style = rowData["_style_" + column.path];
      return (
        <a href={rowData["_link_" + column.path]} style={style}>
          {ret}
        </a>
      );
    } else {
      return ret;
    }
  };

  createKey = (rowData, column) => {
    return rowData._id + (column.path || column.key);
  };

  addDisplay(display) {
    if (display) {
      let d = display.split(" ");
      let ret = " d-none";
      for (let i = 0; i < d.length; i++) {
        ret += " d-" + d[i] + "-table-cell";
      }
      return ret;
    } else {
      return "";
    }
  }

  render() {
    const { data, columns, onChange, service, fullData } = this.props;
    return (
      <tbody>
        {data.map((rowData, index) => (
          <tr key={rowData._id === "new" ? index : rowData._id}>
            {columns.map((column) => (
              <td
                key={this.createKey(rowData, column)}
                className={this.addDisplay(column.display)}
              >
                {column.path === "metadata"
                  ? ""
                  : this.renderCell(
                      fullData,
                      rowData._id,
                      rowData,
                      column,
                      onChange,
                      service
                    )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}
