/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2020
 */

import EntryService from "./entryService";
import { expenseAccountsService } from "./expenseAccountsService";

export const billsItemDefinition = {
  baseUrl: "/bills",
  viewTitle: "Bills Entries",
  editTitle: "Edit Bills Entry",
  createTitle: "New Bills Entry",
  columnNames: ["row", "expenseAccount", "amount", "description"],
  fieldNames: ["row", "expenseAccount", "amount", "description"],
  totalsColumns: ["amount"],
  createPermissions: ["[all]"],
  deletePermissions: ["[all]"],
  readPermissions: ["[all]"],
  writePermissions: ["[all]"],
  fields: {
    row: {
      label: "Row",
      required: true,
      type: "text",
      readonly: true,
    },
    expenseAccount: {
      label: "Expense Account",
      required: true,
      type: "select",
      readonly: false,
      selections: expenseAccountsService,
    },
    amount: {
      label: "Amount",
      required: true,
      type: "currency",
    },
    description: {
      label: "Description",
      required: false,
      type: "text",
    },
  },
};

export let billsItemService = new EntryService(billsItemDefinition);
