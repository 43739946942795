/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import EntryService from "./entryService";

export const usersDefinition = {
  baseUrl: "/settings/users",
  viewTitle: "Users",
  hideNav: true,
  editTitle: "Edit User",
  createTitle: "Create User",
  columnNames: ["name", "email"],
  fieldNames: ["name", "email", "password"],
  readPermissions: ["[all]"],
  createPermissions: ["[all]"],
  deletePermissions: ["[all]"],
  writePermissions: ["[all]"],
  fields: {
    name: {
      label: "Name",
      type: "text",
      length: 255,
      required: true
    },
    email: {
      label: "E-Mail",
      type: "text",
      length: 255,
      required: true
    },
    password: {
      label: "Password",
      required: true,
      type: "password",
      length: 255
    }
  }
};

export let usersService = new EntryService(usersDefinition);
