/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import Input from "./input";
import TextArea from "./textarea";
import Select from "./select";
import Date from "./date";
import CheckBoxes from "./checkBoxes";
import Label from "./label";
import dateUtils from "../../utils/dateUtils";
import RowEntriesTable from "../rowEntriesTable";
import authService from "../../services/authService";
import Validator from "../../utils/Validator";

export default class Field extends Component {
  state = {
    value: this.initValue(),
    hasChanged: false,
    error: "",
    selections: [],
    selectionsService: undefined,
  };

  initValue() {
    let ret = this.props.data[this.props.name];
    let fieldDefinition = this.props.service.getFieldDefinition(
      this.props.name
    );
    if (fieldDefinition.type === "time") {
      ret = dateUtils.sqltime_to_ustime(ret);
    }
    return ret || "";
  }

  async componentDidMount() {
    let selections = [];
    let selectionsService = undefined;
    let fieldDefinition = this.props.service.getFieldDefinition(
      this.props.name
    );

    if (fieldDefinition && fieldDefinition.selections !== undefined) {
      if (typeof fieldDefinition.selections === "string") {
        let values = fieldDefinition.selections.split(",");
        for (let d = 0; d < values.length; d++) {
          let value = values[d].split("|", 2);
          selections.push({ _id: value[0], name: value[value.length - 1] });
        }
      } else if (
        fieldDefinition.selections.isService &&
        fieldDefinition.selections.isService()
      ) {
        selectionsService = fieldDefinition.selections;
        selections = await fieldDefinition.selections.getAllAsync();
      } else if (Array.isArray(fieldDefinition.selections)) {
        selections = fieldDefinition.selections;
      } else {
        selections = [];
        let groups = authService.getGroups();
        for (let g = 0; g < groups.length; g++) {
          if (fieldDefinition.selections[groups[g]] !== undefined) {
            let values = fieldDefinition.selections[groups[g]].split(",");
            for (let v = 0; v < values.length; v++) {
              let value = values[v].split("|", 2);
              selections.push({
                _id: value[0],
                name: value[value.length - 1],
              });
            }
          }
        }
        if (
          selections.length === 0 &&
          fieldDefinition.selections.default !== undefined
        ) {
          let values = fieldDefinition.selections.default.split(",");
          for (let v = 0; v < values.length; v++) {
            let value = values[v].split("|", 2);
            selections.push({
              _id: value[0],
              name: value[value.length - 1],
            });
          }
        }
      }
    }
    this.setState({ selections, selectionsService });
  }

  getSelection() {
    let selection = this.state.selections;
    if (selection === undefined) {
      selection = [{ _id: "1", name: "Name2" }];
    }
    let fieldDefinition = this.props.service.getFieldDefinition(
      this.props.name
    );
    if (fieldDefinition && fieldDefinition.newOption) {
      selection = [...selection, { _id: "_new", name: "New..." }];
    }
    if (fieldDefinition && fieldDefinition.filterSelections) {
      selection = fieldDefinition.filterSelections(selection, this.state.value);
    }

    return selection;
  }

  isReadOnly = () => {
    let { data, name, service } = this.props;
    if (service === undefined) {
      return false;
    }
    let definition = service.getFieldDefinition(name);
    if (definition && definition.readonly === true) {
      return true;
    }

    return !service.hasWritePermissions(name, data && data._author);
  };

  handleBlur = ({ currentTarget: input }) => {
    let value = Validator.transformField(
      input.name,
      input.value,
      this.props.data,
      this.props.service
    );
    let error = Validator.validateField(
      input.name,
      value,
      this.props.service,
      this.props.hideLabel
    );

    if (error) {
      this.setState({ value, error, hasChanged: false });
      this.props.onChange({
        currentTarget: {
          name: input.name,
          value,
        },
      });
      return;
    }

    this.setState({ value, error, hasChanged: false });

    let fieldDefinition = this.props.service.getFieldDefinition(input.name);
    if (fieldDefinition.type === "time") {
      value = dateUtils.ustime_to_sqltime(value);
    } else if (fieldDefinition.type === "date") {
      value = dateUtils.MDY_to_SQL(value);
    }

    this.props.onChange({
      currentTarget: {
        name: input.name,
        value,
      },
    });
  };

  handleCheckboxesChange = ({ currentTarget: input }) => {
    let key = input.value.split("_", 2)[0];
    let value = input.value.split("_", 2)[1];
    let checked = input.checked;

    let data = this.state.value;
    if (data === undefined) {
      data = "";
    }
    if (checked) {
      if (data !== "") {
        data += ",";
      }
      data += value;
    } else {
      let oldValue = data.split(",");
      data = "";
      for (let i = 0; i < oldValue.length; i++) {
        if (oldValue[i] !== value) {
          if (data !== "") {
            data += ",";
          }
          data += oldValue[i];
        }
      }
    }

    this.props.onChange({
      currentTarget: {
        name: key,
        value: data,
      },
    });

    this.setState({ value: data, error: "" });
  };

  handleChange = ({ currentTarget: input }) => {
    this.setState({ value: input.value, hasChanged: true });
  };

  handleRowChange = (rowid, name, value) => {
    let parentField = this.state.value;
    if (parentField === undefined) {
      parentField = [];
    } else if (typeof parentField === "string") {
      parentField = JSON.parse(parentField);
    }
    let found = false;
    for (let i = 0; i < parentField.length; i++) {
      let field = parentField[i];
      if (field._id === rowid) {
        parentField[i][name] = value;
        found = true;
        let allEmpty = true;
        let keys = Object.keys(parentField[i]);
        for (let k = 0; k < keys.length; k++) {
          if (parentField[i][keys[k]] && keys[k] !== "_id") {
            allEmpty = false;
            break;
          }
        }
        if (allEmpty) {
          parentField.splice(i, 1);
        }
        break;
      }
    }
    if (!found) {
      parentField.push({ _id: rowid, [name]: value });
    }

    this.setState({ value: parentField, error: "", hasChanged: false });

    this.props.onChange({
      currentTarget: {
        name: this.props.name,
        value: parentField,
      },
    });
  };

  render() {
    let { name, data, service, hideLabel, setRef } = this.props;

    let readOnly = this.isReadOnly();

    if (readOnly === undefined) {
      readOnly = false;
    }

    let fieldDefinition = service.getFieldDefinition(name);
    if (fieldDefinition.type === "time") {
      if (
        this.state.value !== dateUtils.sqltime_to_ustime(data[name]) &&
        !this.state.hasChanged
      ) {
        this.setState({ value: dateUtils.sqltime_to_ustime(data[name]) });
      }
    } else {
      if (this.state.value !== data[name] && !this.state.hasChanged) {
        this.setState({ value: data[name] });
      }
    }

    let error = this.state.error;

    let label = hideLabel
      ? undefined
      : fieldDefinition.label + (fieldDefinition.required ? "*" : "");
    if (fieldDefinition.type === "select") {
      return (
        <Select
          name={name}
          key={name}
          value={this.state.value}
          label={label}
          options={this.getSelection()}
          service={this.state.selectionsService}
          hasOthers={false}
          onChange={(data) => {
            this.handleChange(data);
            this.handleBlur(data);
          }}
          error={error}
          readOnly={readOnly}
          setRef={setRef}
          history={this.props.history}
          sorted={fieldDefinition.sorted}
        />
      );
    } else if (fieldDefinition.type === "select_other") {
      return (
        <Select
          name={name}
          key={name}
          value={this.state.value}
          label={label}
          options={this.getSelection()}
          service={this.state.selectionsService}
          hasOthers={true}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={error}
          readOnly={readOnly}
          setRef={setRef}
          history={this.props.history}
        />
      );
    } else if (fieldDefinition.type === "month") {
      return (
        <Select
          name={name}
          key={name}
          value={this.state.value}
          label={label}
          options={dateUtils.getMonthOptions(-120, 0)}
          service={undefined}
          hasOthers="fasle"
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={error}
          readOnly={readOnly}
          setRef={setRef}
          history={this.props.history}
        />
      );
    } else if (fieldDefinition.type === "year") {
      let currentYear = new window.Date().getFullYear();
      return (
        <Select
          name={name}
          key={name}
          value={this.state.value}
          label={label}
          options={[
            {
              _id: String(currentYear - 1) + "-01-01",
              name: String(currentYear - 1),
            },
            { _id: String(currentYear) + "-01-01", name: String(currentYear) },
            {
              _id: String(currentYear + 1) + "-01-01",
              name: String(currentYear + 1),
            },
          ]}
          service={undefined}
          hasOthers="fasle"
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={error}
          readOnly={readOnly}
          setRef={setRef}
          history={this.props.history}
        />
      );
    } else if (fieldDefinition.type === "currency") {
      return (
        <Input
          type="currency"
          key={name}
          name={name}
          value={this.state.value}
          label={label}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={error}
          readOnly={readOnly}
          setRef={setRef}
          maxLength={fieldDefinition.length}
        />
      );
    } else if (fieldDefinition.type === "miles") {
      return (
        <Input
          type="text"
          key={name}
          name={name}
          value={this.state.value}
          label={label}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={error}
          readOnly={readOnly}
          setRef={setRef}
          maxLength={fieldDefinition.length}
        />
      );
    } else if (fieldDefinition.type === "time") {
      return (
        <Input
          type="text"
          key={name}
          name={name}
          value={this.state.value}
          label={label}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={error}
          readOnly={readOnly}
          setRef={setRef}
          maxLength={fieldDefinition.length}
        />
      );
    } else if (fieldDefinition.type === "password") {
      return (
        <Input
          name={name}
          label={label}
          error={error}
          value={this.state.value}
          type="password"
          readOnly={readOnly}
          setRef={setRef}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          autoComplete="new-password"
          maxLength={fieldDefinition.length}
        />
      );
    } else if (fieldDefinition.type === "calculated") {
      return (
        <Input
          type="text"
          key={name}
          name={name}
          value={fieldDefinition.value(data, name)}
          label={label}
          readOnly={true}
          setRef={setRef}
        />
      );
    } else if (fieldDefinition.type === "date") {
      return (
        <Date
          key={name}
          name={name}
          value={this.state.value}
          label={label}
          readOnly={readOnly}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={error}
        />
      );
    } else if (fieldDefinition.type === "checkbox") {
      return (
        <CheckBoxes
          name={name}
          key={name}
          value={this.state.value}
          label={label}
          options={this.getSelection()}
          onChange={this.handleCheckboxesChange}
          error={error}
          readOnly={readOnly}
        />
      );
    } else if (fieldDefinition.type === "label") {
      return (
        <Label
          key={name}
          label={label}
          style={this.props.service.getFieldStyle(name)}
        />
      );
    } else if (fieldDefinition.type === "rows") {
      let style = this.props.service.getFieldStyle(name);
      let customData = fieldDefinition.customview;
      const { data } = this.props;
      let data1 = data[name];
      if (typeof data1 === "string") {
        data1 = JSON.parse(data1);
      }
      if (customData) {
        data1 = customData(data, name);
      }
      let serviceRows = this.props.service.getFieldDefinition(name).service;
      return (
        <React.Fragment key={name}>
          <div className="form-group" style={style}>
            {label}
          </div>
          <RowEntriesTable
            fullData={data}
            data={data1}
            onChange={(rowid, fieldName, value) => {
              this.handleRowChange(rowid, fieldName, value);
            }}
            service={serviceRows}
            totalsColumns={serviceRows.definition.totalsColumns}
            globalValidate={this.props.globalValidate}
          />
        </React.Fragment>
      );
    } else if (fieldDefinition.type === "textarea") {
      return (
        <TextArea
          name={name}
          label={label}
          error={error}
          value={this.state.value || ""}
          type="text"
          readOnly={readOnly}
          setRef={setRef}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
      );
    } else {
      return (
        <Input
          name={name}
          label={label}
          error={error}
          value={this.state.value || ""}
          type="text"
          readOnly={readOnly}
          setRef={setRef}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          maxLength={fieldDefinition.length}
        />
      );
    }
  }
}
