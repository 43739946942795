/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import authService from "./authService";
import { expenseAccountsService } from "./expenseAccountsService";
import { bankAccountsService } from "./bankAccountsService";
import EntryService from "./entryService";

export const transactionsDefinition = {
  baseUrl: "/transactions",
  viewTitle: "Transactions",
  editTitle: "Edit Transactions",
  createTitle: "Create Transactions",
  columnNames: ["date", "amount", "balance", "calc_description"],
  totalsColumns: ["amount"],
  fieldNames: [
    "column1",
    "date",
    "column2",
    "transactionDate",
    "columnEnd",
    "expenseAccount",
    "amount",
    "paymenttype",
    "description",
    "itemized",
  ],
  createPermissions: ["[all]"],
  deletePermissions: ["[all]"],
  readPermissions: ["[all]"],
  writePermissions: ["[all]"],
  redirectAfterSave: "/home",
  fields: {
    column1: {
      type: "column",
    },
    date: {
      label: "Date",
      type: "date",
      required: true,
      default: "@Today",
    },
    column2: {
      type: "column",
    },
    transactionDate: {
      label: "Transaction Date",
      type: "date",
      required: false,
      default: "@Today",
    },
    columnEnd: {
      type: "column",
      columnEnd: true,
    },
    amount: {
      label: "Total Amount",
      type: "currency",
      required: true,
    },
    balance: {
      label: "Balance",
      type: "currency",
      required: false,
    },
    paymenttype: {
      label: "Payed with",
      type: "select",
      selections: bankAccountsService,
      sorted: true,
      required: true,
      filterSelections: (selection, value) => {
        return selection.filter(
          (s) =>
            ("," + s.options + ",").indexOf(",showinreceipts,") !== -1 &&
            (!s.user || s.user === authService.getProfile().token)
        );
      },
    },
    expenseAccount: {
      label: "Expense Account",
      type: "select",
      selections: expenseAccountsService,
      sorted: true,
      required: true,
      focus: false,
      filterSelections: (selection, value) => {
        return selection.filter(
          (s) => ("," + s.options + ",").indexOf(",showinreceipts,") !== -1
        );
      },
    },
    description: {
      label: "Description",
      type: "text",
      length: 255,
      required: false,
    },
    calc_description: {
      label: "Description",
      type: "calculated",
      value: (data) =>
        data.description
          ? data.description + " / " + data.stmt_desc
          : data.stmt_desc,
    },
  },
};

export let transactionsService = new EntryService(transactionsDefinition);
