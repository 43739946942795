/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import { Link, NavLink } from "react-router-dom";
import authService from "../services/authService";
import { bankAccountsDefinition } from "../services/bankAccountsService";
import { expenseAccountsDefinition } from "../services/expenseAccountsService";
import { receiptsDefinition } from "../services/receiptsService";
import { billsDefinition } from "../services/billsService";
import { budgetDefinition } from "../services/budgetService";
import { usersDefinition } from "../services/usersService";
import utils from "../utils/utils";
import axios from "axios";

let definitions = [
  {
    baseUrl: "/home",
    viewTitle: "Home",
  },
  {
    baseUrl: "/transactions",
    viewTitle: "Transactions",
  },
  bankAccountsDefinition,
  expenseAccountsDefinition,
  receiptsDefinition,
  billsDefinition,
  budgetDefinition,
  usersDefinition,
  { baseUrl: "/settings", viewTitle: "Settings" },
  {
    baseUrl: "/logout",
    viewTitle: "Logout (" + authService.getUsername() + ")",
  },
];

export default class NavBar extends React.Component {
  state = {
    collapseMenu: true,
    updateAvailable: "",
  };

  handleCloseMenu = () => {
    this.setState({ collapseMenu: true });
  };

  checkUpdate = async () => {
    try {
      let version = await axios.get(
        "https://financial.agolzer.com/financial.build"
      );
      if (version.data) {
        version = version.data;
      }
      const posNewline = version.indexOf("\n");
      if (posNewline !== -1) {
        version = version.substring(0, posNewline);
      }
      this.setState({
        updateAvailable: version !== utils.getBuild() ? version : "",
      });
    } catch (e) {
      console.log("Unable to check update", e);
      this.setState({ updateAvailable: "" });
    }
  };

  renderUpdate() {
    return (
      this.state.updateAvailable && (
        <div style={{ color: "#FF0000" }}>
          Update {this.state.updateAvailable} available.{" "}
          <button
            style={{ color: "#FF0000" }}
            onClick={() => {
              window.location.reload();
            }}
          >
            Install Update
          </button>
        </div>
      )
    );
  }

  async componentDidMount() {
    if (!utils.isLocal()) {
      await this.checkUpdate();
      setInterval(async () => {
        await this.checkUpdate();
      }, 60 * 1000);
    }
  }

  render() {
    let publicUrl = `${process.env.PUBLIC_URL}`;
    let definition = definitions.find((d) =>
      window.location.pathname.startsWith(publicUrl + d.baseUrl)
    );
    let formID;
    if (
      definition &&
      window.location.pathname.startsWith(publicUrl + definition.baseUrl + "/")
    ) {
      formID = window.location.pathname.substring(
        (publicUrl + definition.baseUrl + "/").length
      );
    } else {
      formID = "";
    }
    return (
      <React.Fragment>
        {this.renderUpdate()}
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          {authService.getUsername() !== undefined ? (
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                this.setState({ collapseMenu: !this.state.collapseMenu });
              }}
            >
              <span className="navbar-toggler-icon" />
            </button>
          ) : (
            <span>&nbsp;</span>
          )}
          <Link
            className="navbar-brand d-none d-lg-inline"
            to={`${process.env.PUBLIC_URL}/`}
          >
            Financial
          </Link>
          <div className="navbar-brand d-none d-inline d-lg-none">
            {!definition
              ? "Financial"
              : formID === "new"
              ? definition.createTitle
              : formID
              ? definition.editTitle
              : definition.viewTitle}
          </div>
          {definition &&
          definition.createTitle &&
          !formID &&
          !definition.noPlusCreate ? (
            <Link
              type="button"
              className="btn btn-default btn-lg d-lg-none"
              to={publicUrl + definition.baseUrl + "/new"}
            >
              +
            </Link>
          ) : (
            <React.Fragment>&nbsp;</React.Fragment>
          )}
          <div
            className={
              this.state.collapseMenu
                ? "collapse navbar-collapse"
                : "navbar-collapse"
            }
            id="navbarNavAltMarkup"
          >
            <div className="navbar-nav">
              {authService.getUsername() !== undefined && (
                <React.Fragment>
                  {definitions.map((element) => {
                    if (element.hideNav) {
                      return null;
                    }
                    return (
                      <NavLink
                        key={element.baseUrl}
                        className="nav-item nav-link"
                        to={publicUrl + element.baseUrl}
                        onClick={this.handleCloseMenu}
                      >
                        {element.viewTitle}
                      </NavLink>
                    );
                  })}
                </React.Fragment>
              )}
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}
