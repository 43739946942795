/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";

const Label = ({ label, style }) => {
  return (
    <div key={label} className="form-group" style={style}>
      {label}
    </div>
  );
};

export default Label;
