/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import authService from "../services/authService";
import { usersService } from "../services/usersService";
import { bankAccountsService } from "../services/bankAccountsService";
import { expenseAccountsService } from "../services/expenseAccountsService";
import Complete from "./common/complete";

export default class SettingsPage extends Component {
  render() {
    let publicURL = `${process.env.PUBLIC_URL}`;
    return (
      <div>
        <h3>Settings</h3>
        <Link
          to={publicURL + "/settings/users/" + authService.getProfile().token}
          className="btn btn-secondary"
          style={{ marginBottom: 20 }}
        >
          Profile
        </Link>
        {usersService.hasWritePermissions() && (
          <React.Fragment>
            <br />
            <Link
              to={publicURL + "/settings/users"}
              className="btn btn-secondary"
              style={{ marginBottom: 20 }}
            >
              Users
            </Link>
          </React.Fragment>
        )}
        {expenseAccountsService.hasWritePermissions() && (
          <React.Fragment>
            <br />
            <Link
              to={publicURL + "/settings/expenseaccounts"}
              className="btn btn-secondary"
              style={{ marginBottom: 20 }}
            >
              Expense Accounts
            </Link>
          </React.Fragment>
        )}
        {bankAccountsService.hasWritePermissions() && (
          <React.Fragment>
            <br />
            <Link
              to={publicURL + "/settings/bankaccounts"}
              className="btn btn-secondary"
              style={{ marginBottom: 20 }}
            >
              Bank Accounts
            </Link>
          </React.Fragment>
        )}
        <Complete />
      </div>
    );
  }
}
