/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";

export default class Input extends Component {
  render() {
    let {
      name,
      label,
      error,
      value,
      type,
      setRef,
      readOnly,
      onChange,
      onBlur,
      maxLength,
      autoComplete,
    } = this.props;
    return (
      <React.Fragment>
        <div className={label && "form-group"}>
          {label && <label htmlFor={name}>{label}</label>}
          {readOnly ? (
            <span>{value}</span>
          ) : (
            <React.Fragment>
              <span className="d-none d-print-block">{value}</span>
              <input
                type={type === "currency" ? "number" : type}
                inputMode={type === "currency" ? "decimal" : null}
                name={name}
                id={name}
                value={value || ""}
                className="form-control d-print-none"
                ref={(input) => setRef && setRef(name, input)}
                readOnly={readOnly}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && onBlur) {
                    onBlur({ currentTarget: { name: name, value: value } });
                  }
                }}
                maxLength={maxLength}
                autoComplete={autoComplete}
              />
            </React.Fragment>
          )}
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
      </React.Fragment>
    );
  }
}
