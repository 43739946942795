/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";

export default class SearchBox extends Component {
  render() {
    const { search } = this.props;
    return (
      <React.Fragment>
        <div className="form-group my-0">
          <input
            type="text"
            name="query"
            className="form-control my-3"
            placeholder="Search..."
            value={search.query}
            onChange={e =>
              this.props.onChange({
                ...this.props.search,
                query: e.currentTarget.value
              })
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
