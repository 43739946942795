/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import { budgetService } from "../services/budgetService";
import { receiptsService } from "../services/receiptsService";
import Select from "./common/select";
import Complete from "./common/complete";
import dateUtils from "../utils/dateUtils";
import { expenseAccountsService } from "../services/expenseAccountsService";
import EntriesTable from "./entriesTable";

export default class Budget extends Component {
  state = {
    selectedMonth: dateUtils.Date_to_SQL(new Date()).substring(0, 7),
    monthOptions: [],
    selectedExpenseAccount: "",
    expenseAccounts: [],
    data: [],
    definition: {
      baseUrl: "/budget",
      columnNames: ["expenseAccount", "amount"],
      fields: {
        month: {
          label: "Month",
          type: "date",
          required: true,
        },
        expenseAccount: {
          label: "Budget",
          type: "text",
        },
        amount: {
          label: "Amount",
          type: "currency",
        },
      },
    },
  };

  getService(state) {
    return {
      getFieldDefinition: function (fieldName) {
        return state.definition.fields[fieldName];
      },
      hasWritePermissions: function () {
        return false;
      },
      getColumnNames: function () {
        return state.definition.columnNames;
      },
      getFieldLabel: function (fieldName) {
        return this.getFieldDefinition(fieldName).label;
      },
      getFieldDisplay: function (fieldName) {
        return this.getFieldDefinition(fieldName).display;
      },
      getBaseUrl: function () {
        return state.definition.baseUrl;
      },
      definition: state.definition,
    };
  }

  async componentDidMount() {
    let selectedMonth = ""; //statements[statements.length - 1].date.substring(0, 7);
    let expenseAccounts = await expenseAccountsService.getAllAsync();
    let receipts = await receiptsService.getAllAsyncLookup();
    let budgets = await budgetService.getAllAsync();

    expenseAccounts.sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase()
        ? -1
        : a.name === b.name
        ? 0
        : 1
    );

    let expenseNameById = {};
    let expenseRowById = {};
    let data = [];
    for (let i = 0; i < expenseAccounts.length; i++) {
      let ea = expenseAccounts[i];
      expenseNameById[ea._id] = ea.name;
      expenseRowById[ea._id] = i;
      data.push({
        expenseAccount: ea.name,
      });
    }

    receipts.sort((a, b) => (a.date < b.date ? -1 : a.date > b.date ? 1 : 0));

    let monthOptions = [];
    let months = [];
    let lastMonth = "";
    for (let i = 0; i < receipts.length; i++) {
      if (lastMonth !== receipts[i].date.substring(0, 7)) {
        lastMonth = receipts[i].date.substring(0, 7);
        monthOptions.push({ _id: lastMonth, name: lastMonth });
        months.push(lastMonth);
      }
    }

    budgets.sort((a, b) =>
      a.month < b.month ? 1 : a.month === b.month ? 0 : -1
    );
    budgets.forEach((budget) => {
      let ba = budget.budgetAccounts;
      ba.forEach((b) => {
        data[expenseRowById[b.expenseAccount]]._id = budget._id + "/" + b._id;
        data[expenseRowById[b.expenseAccount]][
          "_link_" + budget.month.substring(0, 7)
        ] = "/budget/" + budget._id;
        data[expenseRowById[b.expenseAccount]][budget.month.substring(0, 7)] =
          b.amount;
      });
    });

    let definition = { ...this.state.definition };
    definition.columnNames = ["expenseAccount"];
    definition.fields = {
      expenseAccount: {
        label: "Budget",
        type: "text",
      },
    };
    monthOptions.forEach((m) => {
      definition.columnNames.push(m.name);
      definition.fields[m.name] = {
        label: m.name,
        type: "currency",
      };
    });

    this.setState({
      expenseAccounts,
      selectedMonth,
      data,
      monthOptions,
      definition,
      months,
    });
  }

  render() {
    return (
      <React.Fragment>
        <h2>Budget</h2>
        <Select
          name="month"
          label="Month"
          options={this.state.monthOptions}
          value={this.state.selectedMonth}
          onChange={({ currentTarget: input }) => {
            this.setState({
              selectedMonth: input.value,
            });
          }}
        />
        <Select
          name="expenseAccount"
          label="Expense Account"
          options={this.state.expenseAccounts}
          value={this.state.selectedExpenseAccount}
          onChange={({ currentTarget: input }) => {
            this.setState({
              selectedExpenseAccount: input.value,
            });
          }}
        />
        <EntriesTable
          columns={["month", "expenseAccount", "amount"]}
          data={this.state.data}
          service={this.getService(this.state)}
          totalsColumns={this.state.months}
        />
        <Complete />
      </React.Fragment>
    );
  }
}
