/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import Select from "./common/select";
import Complete from "./common/complete";
import dateUtils from "../utils/dateUtils";
import { transactionsService } from "../services/transactionsService";
import { billsService } from "../services/billsService";
import { expenseAccountsService } from "../services/expenseAccountsService";
import { bankAccountsService } from "../services/bankAccountsService";
import EntriesTable from "./entriesTable";
import Entries from "./entries";
import Currency from "../utils/currency";
import SearchBox from "./searchBox";
import Pagination from "./common/pagination";

export default class Transactions extends Component {
  state = {
    selectedMonth: dateUtils.Date_to_SQL(new Date()).substring(0, 7),
    monthOptions: [],
    selectedExpenseAccount: "",
    selectedBankAccount: "",
    expenseAccounts: [],
    bankAccounts: [],
    data: [],
    search: { query: "" },
    currentPage: 1,
    pageSize: 100,
  };

  async componentDidMount() {
    let selectedMonth = ""; //statements[statements.length - 1].date.substring(0, 7);
    let expenseAccounts = await expenseAccountsService.getAllAsync();
    expenseAccounts.sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase()
        ? -1
        : a.name === b.name
        ? 0
        : 1
    );

    let bankAccounts = await bankAccountsService.getAllAsync();
    bankAccounts.sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase()
        ? -1
        : a.name === b.name
        ? 0
        : 1
    );

    let data = await billsService.getAllAsync();
    for (let i = 0; i < data.length; i++) {
      data[i]._form = "bills";
    }

    data.sort((a, b) => (a.date < b.date ? 1 : a.date === b.date ? 0 : -1));
    let monthOptions = [];
    let lastMonth = "";
    for (let i = data.length - 1; i >= 0; i--) {
      data[i].amount = -data[i].amount;
      if (lastMonth !== data[i].date.substring(0, 8) + "01") {
        lastMonth = data[i].date.substring(0, 8) + "01";
        monthOptions.push({ _id: lastMonth, name: lastMonth });
      }
    }

    this.setState({
      expenseAccounts,
      bankAccounts,
      selectedMonth,
      data,
      monthOptions,
    });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (search) => {
    this.setState({ search: search, currentPage: 1 });
  };

  render() {
    let filteredData = this.state.data;
    if (this.state.selectedExpenseAccount) {
      filteredData = filteredData.filter(
        (d) => d.expenseAccount === this.state.selectedExpenseAccount
      );
    }
    if (this.state.selectedMonth) {
      filteredData = filteredData.filter(
        (d) =>
          d.date.substring(0, 8) === this.state.selectedMonth.substring(0, 8)
      );
    }
    if (this.state.selectedBankAccount) {
      filteredData = filteredData.filter(
        (d) => d.paymenttype === this.state.selectedBankAccount
      );
    }

    //filter by search term
    filteredData = Entries.searchFilter(
      filteredData,
      this.state.search,
      transactionsService
    );

    filteredData = Entries.flatten(filteredData, transactionsService);

    //calculate totals and format currency
    let balance = new Currency();
    for (let i = filteredData.length - 1; i >= 0; i--) {
      balance.add(filteredData[i].amount);
      if (i === 0 || filteredData[i].date !== filteredData[i - 1].date) {
        filteredData[i].balance = balance.toString();
      }
      filteredData[i].amount = new Currency(filteredData[i].amount).toString();
    }

    //filter by page
    let itemsCount = filteredData.length;
    if (filteredData.length > this.state.pageSize) {
      let fd = [];
      for (
        let i = (this.state.currentPage - 1) * this.state.pageSize;
        i < this.state.currentPage * this.state.pageSize &&
        i < filteredData.length;
        i++
      ) {
        fd.push(filteredData[i]);
      }
      filteredData = fd;
    }

    return (
      <React.Fragment>
        <h2>Transactions</h2>
        <Select
          name="month"
          label="Month"
          options={this.state.monthOptions}
          value={this.state.selectedMonth}
          onChange={({ currentTarget: input }) => {
            this.setState({
              selectedMonth: input.value,
            });
          }}
        />
        <Select
          name="expenseAccount"
          label="Expense Account"
          options={this.state.expenseAccounts}
          value={this.state.selectedExpenseAccount}
          onChange={({ currentTarget: input }) => {
            this.setState({
              selectedExpenseAccount: input.value,
            });
          }}
        />
        <Select
          name="bankAccount"
          label="Bank Account"
          options={this.state.bankAccounts}
          value={this.state.selectedBankAccount}
          onChange={({ currentTarget: input }) => {
            this.setState({
              selectedBankAccount: input.value,
            });
          }}
        />
        <SearchBox search={this.state.search} onChange={this.handleSearch} />
        <EntriesTable
          data={filteredData}
          service={transactionsService}
          sortColumn={""}
          onDelete={this.handleDelete}
          onSort={this.handleSort}
          totalsColumns={this.props.totalsColumns}
        />
        <Pagination
          itemsCount={itemsCount}
          pageSize={this.state.pageSize}
          currentPage={this.state.currentPage}
          onPageChange={this.handlePageChange}
        />
        <Complete />
      </React.Fragment>
    );
  }
}
