/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React from "react";
import Form from "./common/form";
import EntryForm from "./entryForm";
import { bankAccountsService } from "../services/bankAccountsService";

export default class BankAccountsForm extends Form {
  render() {
    return (
      <EntryForm
        service={bankAccountsService}
        match={this.props.match}
        history={this.props.history}
      />
    );
  }
}
