/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import EntriesTable from "./entriesTable";
import Pagination from "./common/pagination";
import _ from "lodash";
import SearchBox from "./searchBox";
import utils from "../utils/utils";
import Complete from "./common/complete";
import dateUtils from "../utils/dateUtils";

export default class Entries extends Component {
  state = {
    entries: [],
    currentPage: 1,
    pageSize: 100,
    search: this.initSearch(),
    selectedGenre: null,
    sortColumn: { path: "name", order: "asc" },
  };

  initSearch() {
    let query = utils.getUrlVar("q") || "";
    return {
      query,
    };
  }

  async componentDidMount() {
    let entries = await this.props.service.getAllAsyncLookup();
    this.setState({ entries: entries, complete: true });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (search) => {
    this.setState({ search: search, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  static splitWithQuotes(value) {
    let inQuote = false;
    let ret = [];
    let current = "";
    for (let i = 0; i < value.length; i++) {
      if (value[i] === '"') {
        if (inQuote && current !== "") {
          ret.push(current);
          current = "";
        }
        inQuote = !inQuote;
      } else if (value[i] === " " && !inQuote) {
        if (current !== "") {
          ret.push(current);
          current = "";
        }
      } else {
        current += value[i];
      }
    }
    if (current !== "") {
      ret.push(current);
    }
    return ret;
  }

  static paginate(items, pageNumber, pageSize) {
    const startIndex = (pageNumber - 1) * pageSize;
    return _(items).slice(startIndex).take(pageSize).value();
  }

  static searchFilter(data, search, service) {
    let columnNames = service.getColumnNames();
    let query = Entries.splitWithQuotes(search.query.toLowerCase());

    let ret = [];
    data.forEach((row) => {
      let found = true;
      if (query) {
        for (let s = 0; s < query.length; s++) {
          let found2 = false;
          for (let cn = 0; cn < columnNames.length; cn++) {
            let columnName = columnNames[cn];
            if (
              !row[columnName] &&
              service.getFieldDefinition(columnName) &&
              service.getFieldDefinition(columnName).type === "calculated"
            ) {
              row[columnName] = service
                .getFieldDefinition(columnName)
                .value(row, columnName);
            }
            if (row[columnName]) {
              if (typeof row[columnName] === "string") {
                let haystack = row[columnName].toLowerCase();
                if (haystack.indexOf(query[s]) !== -1) {
                  found2 = true;
                  break;
                }
              } else if (typeof row[columnName] === "number") {
                let haystack = String(row[columnName]);
                if (haystack.indexOf(query[s]) !== -1) {
                  found2 = true;
                  break;
                }
              } else if (
                typeof row[columnName] === "object" &&
                row[columnName].props &&
                row[columnName].props.children
              ) {
                let haystack = row[columnName].props.children;
                if (haystack.indexOf(query[s]) !== -1) {
                  found2 = true;
                  break;
                }
              }
            }
          }
          if (found2 === false) {
            found = false;
            break;
          }
        }
      }
      if (found) {
        ret.push(row);
      }
    });
    return ret;
  }

  static sortColumn(entries, column, order, service) {
    let definition = service.getFieldDefinition(column);
    if (
      definition &&
      (definition.type === "number" ||
        definition.type === "miles" ||
        definition.type === "currency")
    ) {
      return entries.sort((a, b) => {
        let a1 = a[column] ? parseInt(a[column]) : 0;
        let b1 = b[column] ? parseInt(b[column]) : 0;
        if (a1 < b1) {
          return "asc" === order ? -1 : 1;
        } else if (b1 < a1) {
          return "asc" === order ? 1 : -1;
        } else {
          return 0;
        }
      });
    }
    return entries.sort((a, b) => {
      let a1 = a[column] || "";
      let b1 = b[column] || "";
      if (typeof a1 === "object" || typeof b1 === "object") {
        return 0;
      }
      a1 = a1.toLowerCase();
      b1 = b1.toLowerCase();
      if (a1 < b1) {
        return "asc" === order ? -1 : 1;
      } else if (b1 < a1) {
        return "asc" === order ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      entries: allEntries,
    } = this.state;

    let entries = allEntries;
    if (this.props.customFilter !== undefined) {
      entries = this.props.customFilter(allEntries);
    }

    entries = Entries.flatten(entries, this.props.service);

    entries = Entries.sortColumn(
      entries,
      sortColumn.path,
      sortColumn.order,
      this.props.service
    );

    entries = Entries.forDisplay(entries, this.props.service);

    entries = Entries.searchFilter(
      entries,
      this.state.search,
      this.props.service
    );

    let pageEntries = Entries.paginate(entries, currentPage, pageSize);
    if (this.props.callbackPageEntries) {
      this.props.callbackPageEntries(pageEntries);
    }

    return { totalCount: entries.length, data: pageEntries };
  };

  static flatten(data, service) {
    let data2 = [];
    for (let i = 0; i < data.length; i++) {
      data2[i] = { ...data[i] };
      let keys = service.getColumnNames();
      for (let k = 0; k < keys.length; k++) {
        let key = keys[k];
        let definition = service.getFieldDefinition(key);
        if (definition.json === true) {
          data2[i][key] = data2[i]._json[key];
        }
        if (definition && definition.selections !== undefined) {
          if (
            definition.selections.isService &&
            definition.selections.isService()
          ) {
            let datas = data2[i][key];
            if (datas === undefined) {
              datas = [];
            }
            let out = "";
            for (let s = 0; s < datas.length; s++) {
              if (s > 0) {
                out += ",";
              }
              if (datas && datas[s] && datas[s].name) {
                out += datas[s].name;
              } else {
                out += "undefined";
              }
            }
            data2[i][key] = out;
          }
        } else if (definition && definition.type === "calculated") {
          data2[i][key] = definition.value(data2[i], key);
        }
      }
    }

    return data2;
  }

  static forDisplay(data, service) {
    let data2 = [];
    for (let i = 0; i < data.length; i++) {
      data2[i] = { ...data[i] };
      let keys = service.getColumnNames();
      for (let k = 0; k < keys.length; k++) {
        let key = keys[k];
        let definition = service.getFieldDefinition(key);
        if (definition && definition.type === "date") {
          data2[i][key] = dateUtils.SQL_to_MDY(data2[i][key]);
        }
      }
    }

    return data2;
  }

  render() {
    const { pageSize, currentPage, sortColumn, search } = this.state;

    const { totalCount, data: entries } = this.getPagedData();
    return (
      <div className="col">
        {this.props.service.hasCreatePermissions() &&
          this.props.service.getCreateTitle() && (
            <Link
              to={this.props.service.getBaseUrl() + "/new"}
              className="btn btn-primary d-none d-lg-inline"
              style={{ marginBottom: 20 }}
            >
              {this.props.service.getCreateTitle()}
            </Link>
          )}
        {
          <React.Fragment>
            <SearchBox search={search} onChange={this.handleSearch} />
            <EntriesTable
              data={entries}
              service={this.props.service}
              sortColumn={sortColumn}
              onDelete={this.handleDelete}
              onSort={this.handleSort}
              totalsColumns={this.props.totalsColumns}
            />
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </React.Fragment>
        }
        {this.state.complete && <Complete />}
      </div>
    );
  }
}
