/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2020
 */

import EntryService from "./entryService";
import { expenseAccountsService } from "./expenseAccountsService";

export const budgetItemDefinition = {
  baseUrl: "/budget",
  viewTitle: "Budget Entries",
  editTitle: "Edit Budget Entry",
  createTitle: "New Budget Entry",
  columnNames: ["expenseAccount", "amount"],
  fieldNames: ["expenseAccount", "amount"],
  totalsColumns: ["amount"],
  createPermissions: ["[all]"],
  deletePermissions: ["[all]"],
  readPermissions: ["[all]"],
  writePermissions: ["[all]"],
  fields: {
    expenseAccount: {
      label: "Expense Account",
      type: "select",
      selections: expenseAccountsService,
      sorted: true,
      required: true,
      readonly: true,
      filterSelections: (selection, value) => {
        return selection.filter(
          (s) =>
            ("," + s.options + ",").indexOf(",showinreceipts,") !== -1 ||
            (value && s._id === value)
        );
      },
    },
    amount: {
      label: "Amount",
      required: false,
      type: "currency",
    },
  },
};

export let budgetItemService = new EntryService(budgetItemDefinition);
