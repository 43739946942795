/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import Entries from "./entries";
import { bankAccountsService } from "../services/bankAccountsService";

export default class BankAccounts extends Component {
  render() {
    return <Entries service={bankAccountsService} />;
  }
}
