/* Copyright (C) Andreas Goelzer - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andreas Goelzer <agolzer@agolzer.com>, 2019
 */

import React, { Component } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import BankAccounts from "./components/bankAccounts";
import BankAccountsForm from "./components/bankAccountsForm";
import Budget from "./components/budget";
import BudgetForm from "./components/budgetForm";
import ExpenseAccounts from "./components/expenseAccounts";
import ExpenseAccountsForm from "./components/expenseAccountsForm";
import Receipts from "./components/receipts";
import ReceiptsForm from "./components/receiptsForm";
import Bills from "./components/bills";
import BillsForm from "./components/billsForm";
import Transactions from "./components/transactions";
import LogoutForm from "./components/logoutForm";
import LoginForm from "./components/loginForm";
import NotFound from "./components/notFound";
import NavBar from "./components/navBar";
import HomePage from "./components/homePage";
import authService from "./services/authService";
import Users from "./components/users";
import UsersForm from "./components/usersForm";
import SettingsPage from "./components/settingsPage";
import GeneratePhpFiles from "./services/generatePhpFiles";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "./components/common/dialog";
import axios from "axios";
import utils from "./utils/utils";
import draftStorage from "./utils/draftStorage";

class App extends Component {
  state = { error: undefined, info: undefined, errorLogged: false };

  componentDidCatch(error, info) {
    // Display fallback UI
    //    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    this.setState({ error, info });

    let href = window.location.href;
    let hrefParts = href && href.split("/");
    let data = undefined;
    if (hrefParts && hrefParts.length > 2) {
      data = draftStorage.getDraft(
        hrefParts[hrefParts.length - 2],
        hrefParts[hrefParts.length - 1]
      );
    }

    let url = href.indexOf("localhost:3000")
      ? "/db/logerror.php"
      : utils.getDbUrl() + "logerror.php";
    axios
      .post(url, {
        error: error && error.toString(),
        stack: error && error.stack,
        info: info && info.componentStack && info.componentStack.toString(),
        data: data,
        author: {
          name: authService.getUsername(),
          id: authService.getProfile() && authService.getProfile().token,
        },
        href: window.location.href,
      })
      .then(
        (msg) => {
          console.log("sent error", msg);
          this.setState({ errorLogged: true });
        },
        (err) => {
          console.log("sent error failed", err);
          this.setState({ errorLogged: false });
        }
      );
  }

  render() {
    if (this.state.error || this.state.info) {
      if (this.state.error) {
        console.error(this.state.error);
      }
      if (this.state.info) {
        console.log(this.state.info);
      }
      return (
        <React.Fragment>
          <h1>Error occurred</h1>
          <hr />
          {this.state.errorLogged
            ? "Error has been logged and will be investigated"
            : ""}
        </React.Fragment>
      );
    }
    let publicUrl = `${process.env.PUBLIC_URL}`;
    return (
      <BrowserRouter>
        <ToastContainer />
        <Dialog />
        <NavBar />
        <main className="container">
          {authService.getUsername() ? (
            <React.Fragment>
              <Switch>
                <Route
                  path={`${process.env.PUBLIC_URL}/home`}
                  component={HomePage}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/budget/:id`}
                  component={BudgetForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/budget`}
                  component={Budget}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/logout`}
                  component={LogoutForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/receipts/:id`}
                  component={ReceiptsForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/receipts`}
                  component={Receipts}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/bills/:id`}
                  component={BillsForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/bills`}
                  component={Bills}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/transactions`}
                  component={Transactions}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/expenseaccounts/:id`}
                  component={ExpenseAccountsForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/expenseaccounts`}
                  component={ExpenseAccounts}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/bankaccounts/:id`}
                  component={BankAccountsForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/bankaccounts`}
                  component={BankAccounts}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/users/:id`}
                  component={UsersForm}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings/users`}
                  component={Users}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/settings`}
                  component={SettingsPage}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/not-found`}
                  component={NotFound}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/generatephpfiles`}
                  component={GeneratePhpFiles}
                />
                <Redirect from={publicUrl + "/"} to={publicUrl + "/home"} />
              </Switch>
            </React.Fragment>
          ) : (
            <Switch>
              <Route
                path={`${process.env.PUBLIC_URL}/login`}
                component={LoginForm}
              />
              <Redirect
                from={publicUrl + "/"}
                to={
                  publicUrl +
                  "/login?redirect=" +
                  (window.location.pathname.startsWith("/logout")
                    ? "/"
                    : window.location.pathname)
                }
              />
            </Switch>
          )}
        </main>
      </BrowserRouter>
    );
  }
}

export default App;
